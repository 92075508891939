import React from 'react';
import LazyLoad from 'react-lazyload';

class CommentsAgua extends React.Component {
    render() {
        return (
        <LazyLoad height={1000} offset={100}>
            <div className="row" style={{background: "rgb(204, 153, 102)"}}>
              <div className="span6 offset3">
                <div id="comments" className="comments-area">
                  <h2 className="comments-title">2 comentarios en “<span>INDISPENSABLE EL CONSUMO DE AGUA PARA PREVENIR PROBLEMAS DE SALUD</span>”</h2>
                  <ol className="comment-list">
                    <li id="comment-6517" className="comment even thread-even depth-1">


                      <b className="fn">moises isaias</b>

                      <a href="https://www.ilsevalle.com/blog/2005/10/indispensable-el-consumo-de-agua-para-prevenir-problemas-de-salud-3/#comment-6517/index.html">
                        <time dateTime="2007-05-27T11:36:50+00:00">Domingo, mayo 27, 2007 a las 11:36:AM</time>
                      </a>



                      <p>Esta página consta de toda la información que yo necesitaba, muchas gracias!!!</p>


                    </li>
                    <li id="comment-6518" className="comment odd alt thread-odd thread-alt depth-1">


                      <b className="fn">nayeli</b>

                      <a href="https://www.ilsevalle.com/blog/2005/10/indispensable-el-consumo-de-agua-para-prevenir-problemas-de-salud-3/#comment-6518/index.html">
                        <time dateTime="2009-05-05T14:00:19+00:00">Martes, mayo 5, 2009 a las 2:00:PM</time>
                      </a>



                      <p>Que Compuestos Químicos y sales debe contener el agua ?? y en que cantidades? para que sea útil a nuestro organismo</p>


                    </li>
                  </ol>
                  <p className="no-comments">Los comentarios están cerrados.</p>
                </div>
              </div>
            </div>
</LazyLoad>
        );
    }
}

export default CommentsAgua;
