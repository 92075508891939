import React from 'react';
import LazyLoad from 'react-lazyload';

class CommentsAC extends React.Component {
    render() {
        return (
        <LazyLoad height={1000} offset={100}>
<div className="row" style={{background: "rgb(204, 153, 102)"}}>
<div className="span6 offset3">
          <div id="comments" className="comments-area">
    <h2 className="comments-title">9 comentarios en “<span>VENTAJAS DE LA DIETA MEDITERRÁNEA</span>”</h2>
    <ol className="comment-list">
        <li id="comment-6691" className="comment even thread-even depth-1">
            
                
                    <b className="fn"><a href="http://example.com" rel="external nofollow" className="url">dr alan flores</a></b>
                    
                        <a href="https://www.ilsevalle.com/blog/2005/10/ventajas-de-la-dieta-mediterranea-3/#comment-6691/index.html">
                            <time dateTime="2005-10-21T12:54:34+00:00">Viernes, octubre 21, 2005 a las 12:54:PM</time>
                        </a>

                
                
                    <p>felicidades por su explicación y por su blog. Debemos, creo yo, darle un uso también informativo a este tipo de herramientas.</p>
                    <p>Quisiera saber si la dieta mediterranea es accesible a todos los bolsillos?</p>
                    <p>Gracias de antemano y felicidades nuevamente</p>
                    <p>dr alan @ diabesidad.com</p>

            
        </li>
        <li id="comment-6692" className="comment odd alt thread-odd thread-alt depth-1">
            
                
                    <b className="fn"><a href="http://example.com" rel="external nofollow" className="url">dr alan</a></b>
                    
                        <a href="https://www.ilsevalle.com/blog/2005/10/ventajas-de-la-dieta-mediterranea-3/#comment-6692/index.html">
                            <time dateTime="2005-10-26T21:52:55+00:00">Miércoles, octubre 26, 2005 a las 9:52:PM</time>
                        </a>

                
                
                    <p>Excelente pàgina e informaciòn que nos brinda aqui. La felicito por ello y puse ya una liga a su pagina…siga adelante…</p>

            
        </li>
        <li id="comment-6693" className="comment byuser comment-author-ilse bypostauthor even thread-even depth-1">
            
                
                    <b className="fn"><a href="http://ilsevalle.com" rel="external nofollow" className="url">Ilse</a></b>
                    
                        <a href="https://www.ilsevalle.com/blog/2005/10/ventajas-de-la-dieta-mediterranea-3/#comment-6693/index.html">
                            <time dateTime="2005-10-31T09:48:12+00:00">Lunes, octubre 31, 2005 a las 9:48:AM</time>
                        </a>

                
                
                    <p>Gracias por el comentario tan positivo. Poco a poco iré incrementando el contenido de la página con temas de interés.</p>

            
        </li>
        <li id="comment-6694" className="comment odd alt thread-odd thread-alt depth-1">
            
                
                    <b className="fn"><a href="http://example.com" rel="external nofollow" className="url">Mittraton Carrera Vazquez</a></b>
                    
                        <a href="https://www.ilsevalle.com/blog/2005/10/ventajas-de-la-dieta-mediterranea-3/#comment-6694/index.html">
                            <time dateTime="2005-11-28T17:33:39+00:00">Lunes, noviembre 28, 2005 a las 5:33:PM</time>
                        </a>

                
                
                    <p>FELICITACIONES </p>
                    <p>MUY BUENOS ARTICULOS ESTA PRESENTANDO LICENCIADA, CONTINUE
                         ASI QUE HABRA PERSONAS QUE COMO UN SERVIDOR APROVECHEN ESTOS ARTICULOS
                         PARA ESTARSE ACTUALIZANDO.</p>
                    <p>NUEVAMENTE !!!!!!FELICITACIONES¡¡¡¡¡¡</p>

            
        </li>
        <li id="comment-6695" className="comment byuser comment-author-ilse bypostauthor even thread-even depth-1">
            
                
                    <b className="fn"><a href="http://ilsevalle.com" rel="external nofollow" className="url">Ilse</a></b>
                    
                        <a href="https://www.ilsevalle.com/blog/2005/10/ventajas-de-la-dieta-mediterranea-3/#comment-6695/index.html">
                            <time dateTime="2005-12-28T16:13:12+00:00">Miércoles, diciembre 28, 2005 a las 4:13:PM</time>
                        </a>

                
                
                    <p>Me alegra saber que los artículos hasta ahora mostrados hayan sido de interés y utilidad.
                         Gracias por sus comentarios.</p>

            
        </li>
        <li id="comment-6696" className="comment odd alt thread-odd thread-alt depth-1">
            
                
                    <b className="fn"><a href="http://example.com" rel="external nofollow" className="url">arana lagunas</a></b>
                    
                        <a href="https://www.ilsevalle.com/blog/2005/10/ventajas-de-la-dieta-mediterranea-3/#comment-6696/index.html">
                            <time dateTime="2006-02-11T15:07:18+00:00">Sábado, febrero 11, 2006 a las 3:07:PM</time>
                        </a>

                
                
                    <p>Agradeceria mas menús sobre la dieta mediterranea…. muy bien
                         la paginas nos da muchas ideas de como cuidarnos</p>

            
        </li>
        <li id="comment-6697" className="comment even thread-even depth-1">
            
                
                    <b className="fn"><a href="http://www.ilsevalle.com" rel="external nofollow" className="url">Ilse</a></b>
                    
                        <a href="https://www.ilsevalle.com/blog/2005/10/ventajas-de-la-dieta-mediterranea-3/#comment-6697/index.html">
                            <time dateTime="2006-03-21T09:49:46+00:00">Martes, marzo 21, 2006 a las 9:49:AM</time>
                        </a>

                
                
                    <p>Hola Arana
                         Dentro de poco voy a incluír más menús. Gracias por tu comentario.</p>

            
        </li>
        <li id="comment-6698" className="comment odd alt thread-odd thread-alt depth-1">
            
                
                    <b className="fn"><a href="http://www.ilsevalle.com" rel="external nofollow" className="url">eduardo</a></b>
                    
                        <a href="https://www.ilsevalle.com/blog/2005/10/ventajas-de-la-dieta-mediterranea-3/#comment-6698/index.html">
                            <time dateTime="2009-03-04T10:11:43+00:00">Miércoles, marzo 4, 2009 a las 10:11:AM</time>
                        </a>

                
                
                    <p>mas fino me encanta esta q cool vale x aqui busco toooodoooo</p>
                    <p>Es Super</p>

            
        </li>
        <li id="comment-6699" className="comment even thread-even depth-1">
            
                
                    <b className="fn">ellehcim</b>
                    
                        <a href="https://www.ilsevalle.com/blog/2005/10/ventajas-de-la-dieta-mediterranea-3/#comment-6699/index.html">
                            <time dateTime="2009-03-04T16:57:14+00:00">Miércoles, marzo 4, 2009 a las 4:57:PM</time>
                        </a>

                
                
                    <p>exelente pagina…
                         felicidades!!!!!!!!!!!!
                         pero solo una cosa…les falta ser mas explicitos en cuanto a la
                         informacion ya dada en esta pagina….
                         sigan trabajando en eso…
                         pero muy buen punto el de las dietas y salud</p>

            
        </li>
    </ol>
    <p className="no-comments">Los comentarios están cerrados.</p>
</div>
</div>
</div>
</LazyLoad>
    );
}
}

export default CommentsAC;
