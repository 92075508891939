import React from 'react';
import {graphql} from 'gatsby';
import Helmet from 'react-helmet';
import get from 'lodash/get';
import Img from 'gatsby-image';
import Link from 'gatsby-link';
//import PageTransition from 'gatsby-plugin-page-transitions';

import CommentsAC from '../components/CommentsAC';
import CommentsAgua from '../components/CommentsAgua';
import CommentsMigrana from '../components/CommentsMigrana';
import CommentsSoya from '../components/CommentsSoya';
import CommentsMed from '../components/CommentsMed';
import SEO from '../components/SEO';
// import LoadAdsense from '../components/LoadAdsense';

//import heroStyles from '../components/hero.module.css';
import icon32 from '../assets/images/favicon-32x32.png';
import Template from '../components/layout';

class BlogPostTemplateEs extends React.Component {
  render () {
    const post = get (this.props, 'data.contentfulBlogPost');

    return (
      <Template location={get (this, 'props.location')}>
        <div
          className="wrapper blogpost"
          style={{
            background: 'none 0px 0px repeat scroll rgba(255, 255, 255, 0.7)',
          }}
        >
          <Helmet
            title={`${post.title === 'Anticonceptivos y Nutrición' ? '¿Cómo bajar de peso si tengo el implante subdérmico?' : post.title}`}
            link={[
              {rel: 'shortcut icon', type: 'image/png', href: `${icon32}`},
              {rel: 'preconnect', href: '//googleads.g.doubleclick.net'},
              {rel: 'preconnect', href: '//pagead2.googlesyndication.com'},
              {rel: 'dns-prefetch', href: '//adservice.google.com.mx'},
              {rel: 'preconnect', href: '//adservice.google.com/'},,
              {
                rel: 'preload',
                href: '/fonts/patua-one/patua-one-v7-latin-regular.subset.woff2',
                as: 'font',
                type: 'font/woff2',
                crossorigin: '',
              }
            ]}
          >
            <html lang="es" />
          </Helmet>
          {/* <LoadAdsense /> */}
          <SEO
            seodesc={post.seodesc}
            socialImage={post.heroImage.resolutions.src}
            siteUrl={'https://www.ilsevalle.com/'}
            socialTitle={post.title}
            twitter={'@ilsevallejones'}
          />
          <div>
            <Img
              style={{maxHeight: '400px', height: '61.8vh'}}
              alt={post.heroImage.description}
              title={post.heroImage.description}
              sizes={post.heroImage.sizes}
              loading="eager"
              fadeIn={40}
            />
          </div>
          <div className="container" style={{paddingTop: '1.5em'}}>
            <div className="row">
              <div className="span7 offset1">
                <h1 className="blogh1">{post.title}</h1>
                <ins
                  className="adsbygoogle"
                  style={{
                    display: 'inline-block',
                    width: '336px',
                    height: '280px',
                  }}
                  data-ad-client="ca-pub-2949275046149330"
                  data-ad-slot="5312180609"
                />
                <div
                  dangerouslySetInnerHTML={{
                    __html: post.body.childMarkdownRemark.html,
                  }}
                />
              </div>
              <div className="span4">
                <div className="widget-area">
                  <aside id="text-4" className="widget widget_text">
                    <h3 className="widget-title">Ilse Valle-Jones, MS</h3>{' '}
                    <div className="textwidget">
                      <p>Nutrióloga Psicoterapeuta</p>
                      <Link to="/index_es#contact" style={{color: '#bc360a'}}>
                        Contacto
                      </Link>
                    </div>
                  </aside>
                  <aside id="text-3" className="widget widget_text">
                    {' '}
                    <div className="textwidget">
                      Soy una nutrióloga psicoterapeuta que puede ayudarte a mejorar tu alimentación y tener un estilo de vida saludable.
                    </div>
                  </aside>
                </div>
              </div>
            </div>
          </div>

          {post.title === 'Anticonceptivos y Nutrición' ? <CommentsAC /> : ''}
          {post.title ===
            'Indispensable El Consumo De Agua Para Prevenir Problemas De Salud'
            ? <CommentsAgua />
            : ''}
          {post.title === 'Migraña Vestibular: Su Relación Con La Alimentación'
            ? <CommentsMigrana />
            : ''}
          {post.title === 'Ventajas De La Dieta Mediterránea'
            ? <CommentsMed />
            : ''}
          {post.title === 'Soya, Ventajas De Uso Y Preparación'
            ? <CommentsSoya />
            : ''}

          <hr style={{margin: '0'}} />
        </div>
      </Template>
    );
  }
}

export default BlogPostTemplateEs;

export const pageQuery = graphql`
  query BlogPostBySlugEs($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      publishDate(formatString: "MMMM Do, YYYY")
      heroImage {
        description
        sizes(maxHeight: 800, quality: 40) {
          ...GatsbyContentfulSizes_withWebp
        }
        resolutions(height:800) {
          src
        }
      }
      seodesc
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
