import React from 'react';
import LazyLoad from 'react-lazyload';
import Mailto from 'react-protected-mailto';

import happySmiley from '../assets/images/happy-smiley.png';
//import sadSmiley from '../assets/images/sad-smiley.png';

class CommentsSoya extends React.Component {
    render() {
        return (
        <LazyLoad height={1000} offset={300}>
            <div className="row" style={{background: "rgb(204, 153, 102)"}}>
              <div className="span6 offset3">
                <div id="comments" className="comments-area">
                  <h2 className="comments-title">153 comentarios en “<span>SOYA, VENTAJAS DE USO Y PREPARACIÓN</span>”</h2>
                  <ol className="comment-list">
                    <li id="comment-6540" className="comment even thread-even depth-1">


                      <b className="fn">nut. Loren Gil Alarcon</b>

                      <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6540/index.html">
                        <time dateTime="2005-12-02T18:54:15+00:00">Viernes, diciembre 2, 2005 a las 6:54:PM</time>
                      </a>



                      <p>lo único que puedo comentar es que es una buena pagina, pero
                        necesitamos mas recetas con soya texturizada</p>


                    </li>
                    <li id="comment-6541" className="comment odd alt thread-odd thread-alt depth-1">


                      <b className="fn">isabel castro</b>

                      <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6541/index.html">
                        <time dateTime="2006-01-19T11:56:41+00:00">Jueves, enero 19, 2006 a las 11:56:AM</time>
                      </a>



                      <p>como puedo hacer yogurt de soya si tengo leche de soya en polvo</p>


                    </li>
                    <li id="comment-6542" className="comment byuser comment-author-ilse bypostauthor even thread-even depth-1">


                      <b className="fn"><a href="http://ilsevalle.com" rel="external nofollow" className="url">Ilse</a></b>

                      <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6542/index.html">
                        <time dateTime="2006-01-20T17:22:30+00:00">Viernes, enero 20, 2006 a las 5:22:PM</time>
                      </a>



                      <p>Hola Loren:</p>
                      <p>Gracias por tu comentario, y procuraré añadir más recetas con soya texturizada pronto.</p>


                    </li>
                    <li id="comment-6543" className="comment byuser comment-author-ilse bypostauthor odd alt thread-odd thread-alt depth-1">


                      <b className="fn"><a href="http://ilsevalle.com" rel="external nofollow" className="url">Ilse</a></b>

                      <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6543/index.html">
                        <time dateTime="2006-01-20T17:40:16+00:00">Viernes, enero 20, 2006 a las 5:40:PM</time>
                      </a>



                      <p>Hola Isabel:
                        Aquí escribo una receta para preparar yogurt con leche de soya. Lo único es que necesitas preparar la leche de soya en polvo para que salgan 2 litros de leche de soya.
                        (Tip: 10 cucharadas copeteadas -120 g- de leche en polvo en 900 ml de agua hacen 1 litro de leche.)
                        No es necesario tener búlgaros para poder hacer yogurt; basta con que utilices yogurt con probióticos (microorganismos llamados también cultivos lácteos); pero si cuentas con búlgaros, sustituye el vasito de yogurt por los búlgaros.</p>
                      <p>Ingredientes: </p>
                      <p>2 litros de leche de soya
                        1 vasito de yogurt natural que contenga probióticos (cultivos lácteos) <strong>ó bulgaros</strong>
                        unas gotitas de esencia de vainilla (opcional) </p>
                      <p>Preparación: </p>
                      <p>Hierva la leche y déjela enfriar.
                        Para probar la temperatura de la leche, prueba si tu mano soporta el calor: coloca un dedo y cuente hasta diez, si puedes mantener la mano en la leche, está lista para agregar el yogurt y la esencia revolviendo bien.
                        Mantén la mezcla en un lugar cubierto y abrigado para que se espese la mezcla.
                        Puedes hacerlo en la noche y en la mañana estará listo. En verano tarda 4 horas aproximadamente.</p>


                    </li>
                    <li id="comment-6544" className="comment even thread-even depth-1">


                      <b className="fn">Francisco</b>

                      <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6544/index.html">
                        <time dateTime="2006-01-22T13:54:44+00:00">Domingo, enero 22, 2006 a las 1:54:PM</time>
                      </a>



                      <p>Es interesante todo lo que dices sobre la soya nada mas tengo una
                        duda dices que la soya tiene 38% de proteína
                        más que cualquier otro alimento de origen animal o vegetal
                        no procesado. 38% en que cantidad gramos, kilos, etc.
                        ¿100gr de soya cuanta proteina tiene? en gramos</p>


                    </li>
                    <li id="comment-6545" className="comment byuser comment-author-ilse bypostauthor odd alt thread-odd thread-alt depth-1">


                      <b className="fn"><a href="http://ilsevalle.com" rel="external nofollow" className="url">Ilse</a></b>

                      <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6545/index.html">
                        <time dateTime="2006-01-23T15:19:19+00:00">Lunes, enero 23, 2006 a las 3:19:PM</time>
                      </a>



                      <p>Hola Francisco:<br/>
                        Que bueno que escribes, creo que hubo una confusión, el artículo dice:
                        “La soya contiene 38% de proteína, más que cualquier otro alimento de origen animal o vegetal no procesado, además de 30% de hidratos de carbono, 18% de aceite y 14% de humedad”.<br/>
                        Esto significa que la soya contiene 38% de proteína, lo cual es una cantidad mayor que el porcentaje de proteína que contienen otros productos de origen animal (en promedio, éstos tienen un 20% de proteína), por lo tanto, la soya tiene 18% mayor cantidad de proteína que los productos de origen animal.<br/>
                        Ahora, estos valores porcentuales, <strong>son promedios</strong>, por lo que cierto tipo de carne en específico puede contener mayor o menor cantidad de proteínas.<br/>
            Regresando a tu duda, 38% de proteína equivale a 38 g de proteína en 100 g de soya.</p>


            </li>
                <li id="comment-6546" className="comment even thread-even depth-1">


                <b className="fn">Regina Rivera</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6546/index.html">
                <time dateTime="2006-02-11T21:22:21+00:00">Sábado, febrero 11, 2006 a las 9:22:PM</time>
            </a>



                <p>Quiero compartir con ustedes una receta para hacer un rico y nutritivo
            atole con frijol de soya..Por la noche poner a remojar 21 frijoles de
            soya hasta cubrirlos con agua, en otro recipiente remojar trigo, cebada perla y
            avena de igual manera….Por la mañana lavar bien el frijol y licuarlo
            con una taza de agua; aparte ya deben tener hirviendo 1L de agua y canela, ahí&nbsp;se
            agrega la soya molida y dejar hervir 20 minutos. Posteriormente
            se muelen los demás ingredientes con otra taza de agua y se mezcla
            al frijol de soya que estará hirviendo. Esperar a que hierva y apagar.
            Este atole nos ayudara bastante, sobre todo nos nutrirá mucho.</p>


            </li>
                <li id="comment-6547" className="comment odd alt thread-odd thread-alt depth-1">


                <b className="fn"><a href="http://ilsevalle.com" rel="external nofollow" className="url">Ilse</a></b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6547/index.html">
                <time dateTime="2006-02-13T12:10:30+00:00">Lunes, febrero 13, 2006 a las 12:10:PM</time>
            </a>



                <p>Muchas gracias Regina, es muy valiosa tu aportación.</p>


            </li>
                <li id="comment-6548" className="comment even thread-even depth-1">


                <b className="fn">Benjamin Alvarez</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6548/index.html">
                <time dateTime="2006-02-14T15:32:26+00:00">Martes, febrero 14, 2006 a las 3:32:PM</time>
            </a>



                <p>Hola es excelente todo lo relacionado con la soya, yo compro la soya en tiendas naturistas lo que es chicharrón, chorizo, salchicha, carne de cerdo, de res, etc. todo a base de soya, pero es muy caro adquirirlo en estas tiendas, quisiera saber si tienen la forma de poder elaborarlo uno mismo, pues es mas caro que comprarlo de origen animal.</p>


            </li>
                <li id="comment-6549" className="comment odd alt thread-odd thread-alt depth-1">


                <b className="fn">José Leonel Prada</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6549/index.html">
                <time dateTime="2006-02-16T06:41:47+00:00">Jueves, febrero 16, 2006 a las 6:41:AM</time>
            </a>



                <p>Son personas muy conocedoras del tema. Me gustaría felicitarlos por
            tan interesantes temas acerca de la soya.<br/>
            Quisiera que me enviaran un comentario de como hacer la leche en polvo
            y si se puede hacer manualmente, sin tener que recurrir a ninguna
            maquina para tal proceso.</p>
                <p>Muchas gracias.</p>
                <p>Att.
            José Leonel Prada</p>


            </li>
                <li id="comment-6550" className="comment even thread-even depth-1">


                <b className="fn"><a href="http://ilsevalle.com" rel="external nofollow" className="url">Ilse</a></b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6550/index.html">
                <time dateTime="2006-02-16T13:18:12+00:00">Jueves, febrero 16, 2006 a las 1:18:PM</time>
            </a>



                <p>Hola José Leonel<br/>
            Para la elaboración de la leche en polvo, es necesario un proceso
            industrializado, en donde se utiliza maquinaria; la leche en polvo no se
            puede elaborar en casa.</p>


            </li>
                <li id="comment-6551" className="comment odd alt thread-odd thread-alt depth-1">


                <b className="fn">Vicka</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6551/index.html">
                <time dateTime="2006-02-18T04:33:10+00:00">Sábado, febrero 18, 2006 a las 4:33:AM</time>
            </a>



                <p>Hola</p>
                <p>¿Me permiten hacerles un comentario? ojala sea de vuestro agrado.
            Tengo una receta de leche de soya y ahí les va:<br/>
            Ingredientes:</p>
                <p>1libra (450 g) de frijol de soya
            2 litros de agua
            Preparación:</p>
                <p>Se remoja el frijol de un día para otro, no botar el agua, con ella riega
            tus plantas.
            Luego licuarlo en los dos litros de agua, no hay necesidad de pelar el
            frijol.</p>
                <p>Una vez licuado tienes 2 opciones:
            1.- Lo tamizas o cuelas con un cedazo y lo haces hervir con canela y
            anís por 30 minutos, y ya esta , ó
            2.- Lo hierves todo sin colar por 30 minutos y luego lo cuelas y ya está.</p>
                <p>Con el bagazo exprimido puedes hacer tu carne que se asemeja a la
            textura del pescado; tambien lo puedes usar en torrejitas, en la sopa, en tortas, budines, etc.</p>


            </li>
                <li id="comment-6552" className="comment even thread-even depth-1">


                <b className="fn"><a href="http://ilsevalle.com" rel="external nofollow" className="url">Ilse</a></b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6552/index.html">
                <time dateTime="2006-02-20T11:47:34+00:00">Lunes, febrero 20, 2006 a las 11:47:AM</time>
            </a>



                <p>Muchas gracias por la receta, te lo agradezco mucho.</p>


            </li>
                <li id="comment-6553" className="comment odd alt thread-odd thread-alt depth-1">


                <b className="fn">marinette tovar sanchez</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6553/index.html">
                <time dateTime="2006-02-28T20:45:06+00:00">Martes, febrero 28, 2006 a las 8:45:PM</time>
            </a>



                <p>Mil gracias por la receta del yogurt y de la leche, al igual que el de la
            carne de soya, no saben cuano tiempo tenia buscándolo.</p>


            </li>
                <li id="comment-6554" className="comment even thread-even depth-1">


                <b className="fn">MARTHA LUCIA</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6554/index.html">
                <time dateTime="2006-03-02T08:10:24+00:00">Jueves, marzo 2, 2006 a las 8:10:AM</time>
            </a>



                <p>Hola, felicitaciones por las informaciones.</p>
                <p>Me han diagnosticado osteoporosis y tengo 51 años de edad. Quisiera saber si con la soya podría tener una rehabilitación a esta enfermedad y cómo podría utilizarla, ya que nunca la he usado.</p>
                <p>Gracias.</p>


            </li>
                <li id="comment-6555" className="comment odd alt thread-odd thread-alt depth-1">


                <b className="fn"><a href="http://ilsevalle.com" rel="external nofollow" className="url">Ilse</a></b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6555/index.html">
                <time dateTime="2006-03-02T12:40:16+00:00">Jueves, marzo 2, 2006 a las 12:40:PM</time>
            </a>



                <p>Hola Martha Lucía<br/>
            El crecimiento de los huesos (esto significa que siguen creciendo y
                                          almacenando calcio) es desde que nacemos hasta los 24 años
            aproximadamente, durante el embarazo y la lactancia es necesario
            consumir mayor cantidad de calcio pues el requerimiento es mayor,
            y se desea prevenir la descalcificación.<br/>
            Si tu tienes osteoporosis, tus huesos ya sufrieron una descalcificación.
            Te recomiendo que consumas alimentos ricos en calcio; uno de estos
            alimentos es la soya, puedes comprar germinado de soya y consumirlo
            en las ensaladas; también puedes consumir Tofu (es como un queso
                                                            hecho a base de soya), ese lo puedes cortar en cuadritos y agrear a
            ensaladas ó caldos de res y pollo sin grasa; puedes tomar <b>leche de soya</b>
            si te agrada el sabor; otra presentación de la soya es la llamada
            “soya texturizada”, ésta no es mas que una mezcla hecha a base de soya
            y está deshidratada, para poder utilizarla, necesitas hidratarla:
            en un recipiente con agua caliente, agrega trozos de soya texturizada
            y ¡listo!, puedes utilzarla como carne de pollo, res ó atún preparándola
            como en la receta original, lo único que sustituyes es la soya por la carne.
            Puedes conseguir muchos de los productos que mencioné en tiendas naturistas y algunos en el supermercado.
            Hacer ejercicio regularmente ayuda a la absorción de calcio, así que sugiero
            que comiences una rutina diaria.<br/>
            Otros productos altos en calcio son:<br/>
            Brócoli<br/>
            Espinacas<br/>
            Quesos (preferir los blancos porque tienen menos grasa)<br/>
            Yogurt<br/>
            Charales<br/>
            Sardinas<br/>
            Yogurt<br/>
            Leche<br/>
            Espero que estos consejos sean de utilidad.</p>


            </li>
                <li id="comment-6556" className="comment even thread-even depth-1">


                <b className="fn">LUZ ALINA NUMA</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6556/index.html">
                <time dateTime="2006-03-04T10:50:12+00:00">Sábado, marzo 4, 2006 a las 10:50:AM</time>
            </a>



                <p>Hola:</p>
                <p>Te felicitó por tú claridad para redactar.</p>
                <p>Soy una persona que vende el frijol de soya empcado con la <b>receta para elaborar la leche de soya en casa</b>, y un recetario para hacer tofu, milanesas, albondigas, huevo a la mexicana,etc.</p>
                <p>Si les interesa, mi celular es04455 3409 3002 ó 2974 77 25.</p>
                <p>Después de 10 k. el costo es de 2222220 pesos.</p>
                <p>Saludos y espero les interese mi propuesta.</p>


            </li>
                <li id="comment-6557" className="comment odd alt thread-odd thread-alt depth-1">


                <b className="fn"><a href="http://www.ilsevalle.com" rel="external nofollow" className="url">Ilse</a></b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6557/index.html">
                <time dateTime="2006-03-06T15:54:27+00:00">Lunes, marzo 6, 2006 a las 3:54:PM</time>
            </a>



                <p>Hola Luz Alina<br/>
            Gracias por tu oferta, la voy a dejar en mi página por si le interesa a alguien.
            Sólo te recomiendo checar el precio, porque no se me hace lógico.
            Si quieres, dime el precio correcto, y edito tu comentario.
            Saludos y nuevamente gracias.</p>


            </li>
                <li id="comment-6558" className="comment even thread-even depth-1">


                <b className="fn">Lorna</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6558/index.html">
                <time dateTime="2006-03-07T05:40:27+00:00">Martes, marzo 7, 2006 a las 5:40:AM</time>
            </a>



                <p>Ilse:
            ¿Podrías decirme por favor si es recomendable utilizar la soya cuando padeces artrosis? He leido que los reumatologos se contradicen y hay que eliminar las proteinas cuando aparece esta enfermedad.
            Gracias por tus comentarios.</p>


            </li>
                <li id="comment-6559" className="comment odd alt thread-odd thread-alt depth-1">


                <b className="fn"><a href="http://ilsevalle.com" rel="external nofollow" className="url">Ilse</a></b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6559/index.html">
                <time dateTime="2006-03-08T12:58:18+00:00">Miércoles, marzo 8, 2006 a las 12:58:PM</time>
            </a>



                <p>Hola Lorna
            Primero comencemos por definir qué es la artrosis:
            La artrosis consiste en la pérdida progresiva de cartílago articular; y
            muchas personas arriba de los 70 años la padecen.
            Es importante ejercitarse para incrementar la movilidad articular y
            fortalecer los músculos. Cuando existe obesidad, empeora la artrosis
            por lo que la persona afectada tiende a disminuir su movilidad
            y por lo tanto tiende a aumentar su grado de obesidad.
            Por lo anterior se convierte en un círculo vicioso.
            Algunas recomendaciones alimentarias son:</p>
                <ul>
                <li>Consumir una dieta equilibrada que evite el aumento de peso.</li>
                <li>Seguir una dieta de adelgazamiento en caso de obesidad.</li>
                <li>Para contrarestar los efectos indeseables de los medicamentos,
            se pueden tomar antiinflamatorios junto con alimentos para evitar
            irritación estomacal.</li>
                <li>La ingesta de proteínas no tiene repercución, ya que la artrosis
            es una enfermedad degenerativa articular (es decir, que se las
                                                      articulaciones están gastadas); pero es importante consumir la cantidad
            que tu cuerpo requiere y no caer en deficiencias ó excesos.</li>
            </ul>
                <p>Hay que resaltar que las dietas terapéuticas deben ser personales
            y dependen de tu situación médica y nutrimental. La especificación
            de alimentos (menús concretos) útil para tí no sirve para todos los
            pacientes; por ello, la información que ofresco es genérica.</p>


            </li>
                <li id="comment-6560" className="comment even thread-even depth-1">


                <b className="fn">Angela Maria Aguirre López</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6560/index.html">
                <time dateTime="2006-03-15T08:18:56+00:00">Miércoles, marzo 15, 2006 a las 8:18:AM</time>
            </a>



                <p>Buenos dias:</p>
                <p>Ilse, te&nbsp;quiero felicitar por el solo hecho de tener esta pagina que es de una excelente consulta. Primera vez que la consulto porque la desconocia totalmente.</p>
                <p>Estoy formando una empresa de productos lácteos a base de soya, y no se como es la elaboracion de la avena de soya, me gustaria que me pudieran&nbsp;ayudar con esto.</p>
                <p>Muchas gracias y estare a la espera de una pronta respuesta.</p>


            </li>
                <li id="comment-6561" className="comment odd alt thread-odd thread-alt depth-1">


                <b className="fn"><a href="http://example.com" rel="external nofollow" className="url">iveth</a></b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6561/index.html">
                <time dateTime="2006-03-18T20:20:49+00:00">Sábado, marzo 18, 2006 a las 8:20:PM</time>
            </a>



                <p>Hola!</p>
                <p>Felicidades por este sitio, de verdad que es muy informativo y muy
            claro, acabo de iniciarme en la vida vegana y estoy investigando acerca
            de los alimentos preparados con soya. lo unico que no me gusta es que
            la carne y quesos, leche, tofu y demas son caros en los supermercados,
            (vivo en Estados Unidos), quisiera saber si alguien sabe como conseguir estos productos a un precio mas accesible.
            <b>Gracias por la receta de la leche de soya!</b></p>


            </li>
                <li id="comment-6562" className="comment even thread-even depth-1">


                <b className="fn">Felipe Jarrín</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6562/index.html">
                <time dateTime="2006-03-21T06:21:05+00:00">Martes, marzo 21, 2006 a las 6:21:AM</time>
            </a>



                <p>Estimada Ilse,
            Te saluda Felipe Jarrín desde Ecuador. Es muy interesante la información que
            publicas en esta página, te felicito. Estoy interesado en desarrollar
            compotas o papillas para bebés con soya, por lo cual, me gustaría
            saber algunas recetas y procedimientos para elaborar dichos productos,
            por favor si alguien conoce sobre recetas, le estaría muy agradecido.
            Un gusto saludarte.
            Felipe</p>


            </li>
                <li id="comment-6563" className="comment odd alt thread-odd thread-alt depth-1">


                <b className="fn"><a href="http://www.ilsevalle.com" rel="external nofollow" className="url">Ilse</a></b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6563/index.html">
                <time dateTime="2006-03-21T09:54:24+00:00">Martes, marzo 21, 2006 a las 9:54:AM</time>
            </a>



                <p>Hola Felipe e Iveth
            Dejo aquí sus comentarios por si alguna persona familiarizada con los porcesos industriales para la elaboración de estos productos les puede resolver estas dudas.
            Gracias por sus comentarios</p>


            </li>
                <li id="comment-6564" className="comment even thread-even depth-1">


                <b className="fn"><a href="http://www.ilsevalle.com" rel="external nofollow" className="url">Ma.Teresa</a></b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6564/index.html">
                <time dateTime="2006-03-21T11:28:21+00:00">Martes, marzo 21, 2006 a las 11:28:AM</time>
            </a>



                <p>Hola Ilse</p>
                <p>Felicidades por tan maravillosa página, he deseado hacer leche de soya, ya que estoy entrando en la menopausia y se que tanto el tofu como la leche de soya son excelentes. Me encantaria que me dieran la receta de como elaborar el tofu en casa,</p>
                <p>mil gracias a&nbsp;por&nbsp;las&nbsp;aportaciones</p>
                <p>Dios les bendiga</p>
                <p>Ma. Teresa</p>


            </li>
                <li id="comment-6565" className="comment odd alt thread-odd thread-alt depth-1">


                <b className="fn"><a href="http://www.ilsevalle.com" rel="external nofollow" className="url">Ilse</a></b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6565/index.html">
                <time dateTime="2006-03-21T16:42:31+00:00">Martes, marzo 21, 2006 a las 4:42:PM</time>
            </a>



                <p>Hola Ma Teresa</p>
                <p>Te escribo una <b>receta para elaborar Tofu</b>, aunque también&nbsp;lo puedes adquirir en tiendas naturistas.&nbsp;</p>
                <p>INGREDIENTES</p>
                <p>1 taza de soya amarilla</p>
                <p>2 ml de&nbsp;agua de mar ó limón ó&nbsp;vinagre natural</p>
                <p>PREPARACIÓN:
            Limpiar las alubias de soya.</p>
                <p>Remojar las alubias de soya por un mínimo de 10 horas.</p>
                <p>Colar las alubias y quitarles el pellejo frotándolas con un paño.</p>
                <p>Picar las alubias en un procesador de alimentos ó máquina equivalente.</p>
                <p>Agregar&nbsp;8 tazas de agua a la soya triturada y poner en la estufa a flama alta.</p>
                <p>Cuando empiece a hervir, poner a fuego lento (vigilar bien ya que puede subir).</p>
                <p>Cocer duratne 10 min.</p>
                <p>Colar con un colador de tela.</p>
                <p>La parte liquida es la leche de soya y la sólida se llama okara.</p>
                <p>Una vez que se haya filtrado todo el liquido,&nbsp;aprieta bien la parte sólida para quitarle las ultimas gotas.</p>
                <p>Disolver el&nbsp;agua de mar&nbsp;en un poquito de agua templada e ir añadiéndolo poco a poco, revolviendo bien con una cuchara de madera.</p>
                <p>En 15 minutos la leche se habrá cortado; si no llega a cortarse, añadir un poco mas de&nbsp;agua de mar&nbsp;(hay que dar un poco de tiempo).</p>
                <p>Una vez cortada, dejar reposar unos 20-30 minutos</p>
                <p>Una vez que se vea el suero en la parte superior, colar todo.</p>
                <p>Tomar la cuajada y ponerla en un recipiente donde se pueda prensar la pasta y que a la vez se pueda recoger el líquido que sale.</p>
                <p>A las dos horas de prensado tendremos la consistencia de un queso fresco, si aumentamos el tiempo se pondrá la pasta algo mas compacta.</p>
                <p>NOTA: El tiempo de prensado esta en función de la dureza del agua, tipo de soya y clase de cuajo. Se conserva aproximadamente una semana en el refrigerador, tiene que estar bañada en agua y el agua hay que cambiar cada dos días.</p>


            </li>
                <li id="comment-6566" className="comment even thread-even depth-1">


                <b className="fn">Angela</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6566/index.html">
                <time dateTime="2006-03-24T13:31:57+00:00">Viernes, marzo 24, 2006 a las 1:31:PM</time>
            </a>



                <p>Hola Ilse</p>
                <p>Tengo una bebé de 7 meses. La alimenté con pecho hasta los
            6 meses y luego comencé con alimentos y leche de soya. No le doy
            leche de soya porque sea intolerante a la lactosa, sino porque
            tengo la idea de que es muy buena. De acuerdo con la informacion
            que&nbsp;tu tienes de la leche de soya, quisiera saber si con este tipo de
            formula estoy alimentando bien a mi bebé, o si es mejor utilizar la
            formula normal.</p>
                <p>Gracias</p>


            </li>
                <li id="comment-6567" className="comment odd alt thread-odd thread-alt depth-1">


                <b className="fn">Sheila</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6567/index.html">
                <time dateTime="2006-03-27T07:13:52+00:00">Lunes, marzo 27, 2006 a las 7:13:AM</time>
            </a>



                <p>Hola Ilse</p>
                <p>Felicitaciones por la página, muy buena información.</p>
                <p>Tengo unas consultas adicionales:<br/>
            1. ¿Por qué varían los valores nutritivos entre la leche de soya y la proteína texturizada de soya (PTS)?
            2. ¿Cuál es la diferencia de valor nutritivo entre la leche de soya y su resíduo?<br/>
            3. ¿Existe forma de preparar la proteína texturizada de soya en casa?<br/>
            4. ¿Cuál es la diferencia de valor nutritivo entre la proteína texturizada de soya y el glúten, cuál tiene más proteína, cuáles son los valores exactos (calorías, carbohidratos, proteínas, fibra)? Busqué en la red pero no encontré la respuesta.</p>


            </li>
                <li id="comment-6568" className="comment even thread-even depth-1">


                <b className="fn"><a href="http://www.ilsevalle.com" rel="external nofollow" className="url">Ilse</a></b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6568/index.html">
                <time dateTime="2006-03-29T17:24:56+00:00">Miércoles, marzo 29, 2006 a las 5:24:PM</time>
            </a>



                <p>Hola Sheila<br/>
            Aquí tengo la respuesta a tus preguntas. Muy interesantes, por cierto:<br/>
            1. Los valores nutrimentales entre la Leche de soya y la Proteína de Soya Texturizada son muy similares; pero llegan a variar&nbsp;por que a pesar de que ambas se elaboran a base de soya, el proceso de su elaboración es diferente: La Leche de Soya se elabora a partir de granos de soya deshidratados y molidos; y la Proteína Texturizada de Soya se elabora a base de granos de soya pre-cocidos y procesados.<br/>
            Adicionalemte, la presentación del producto puede hacer que haya variaciones en el contenido nutrimental; es decir, la Proteína Texturizada de Soya puede tener molido fino, medio ó grueso, y cada una de estas presentaciones tiene variaciones en su contenido nutrimental.<br/>
            Otro factor que influye es que dependiendo de la marca del producto,&nbsp;el proceso de&nbsp;elaboración de estos productos puede variar un poco, así que cada marca tendrá diferentes valores nutrimentales.<br/>
            2. Enlisto el&nbsp;valor nutritivo por cada 100g de&nbsp;Leche de Soya en polvo y su residuo. La diferencia&nbsp;se debe a que el residuo tiene restos del frijol de soya molido, pero no hay que olvidar que gran parte de los carbohidratos del residuo de frijol de soya ya no están debido a que&nbsp;se solubilizaron en el agua en que se remojan&nbsp;los frijoles antes de la elaboración de la leche de soya.</p>
                <p><strong>Leche de Soya en Polvo</strong></p>
                <p>Energía&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;380 Kcal</p>
                <p>Carbohidratos&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;20 g</p>
                <p>Fibra&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; 0 g</p>
                <p>Proteínas&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;30 g</p>
                <p>Grasas&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 20 g</p>
                <p>&nbsp;</p>
                <p><strong>Residuo de Leche de Soya</strong></p>
                <p>Energía&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 400 Kcal</p>
                <p>Carbohidratos&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;30 g</p>
                <p>Fibra&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10 g</p>
                <p>Proteínas&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;35 g</p>
                <p>Grasas&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 20 g
                <br/> 3. No existe una receta casera para la elaboración de Proteína Texturizada de Soya; pero una alternativa es que el residuo de la leche de soya (los frijoles de soya licuados) se deshidrate poniendolo en el horno unos minutos o bajo el sol inteso de un día de verano; y con esto puedes&nbsp;utilizar este producto en&nbsp;recetas que originalmente&nbsp;llevavan carne.<br/>
            4. A continuación, enlisto el valor nutrimental por cada 100 g de la Proteína Texturizada
            de Soya y el Gluten:<br/>
                <strong>Proteína Texturizada de Soya</strong><br/>
            Energía&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 375 Kcal</p>
                <p>Carbohidratos&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 37.5 g&nbsp;</p>
                <p>Fibra&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &lt; 1 g<br/>Proteínas&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 50 g<br/>Grasas&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; 0 g&nbsp;&nbsp;<br/>Gluten Energía&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 315 Kcal<br/>Carbohidratos&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;13.5 g<br/>Fibra&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; &lt; 1 g<br/>Proteínas&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 85 g<br/>Grasas&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 1.5 g<br/>&nbsp;&nbsp;</p>


            </li>
                <li id="comment-6569" className="comment odd alt thread-odd thread-alt depth-1">


                <b className="fn"><a href="http://www.ilsevalle.com" rel="external nofollow" className="url">Ilse</a></b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6569/index.html">
                <time dateTime="2006-03-29T18:36:00+00:00">Miércoles, marzo 29, 2006 a las 6:36:PM</time>
            </a>



                <p>Hola Angela</p>
                <p>No mencionas si dejaste de producir leche después de que tu bebé cumplió 6 meses; cabe resaltar que la leche materna se puede y debe seguir dando al bebe hasta el año de edad (comenzando la introducción de alimentos distintos&nbsp;a la leche materna entre los 4 y 6 meses).</p>
                <p>¿La leche de soya que le das a tu bebé es un fórmula especialemente diseñada para bebés de esa edad? Hay que recordar que los bebés necesitan distintas cantidades de carbohidratos, proteínas y grasas que un adulto. Sólo un comentario: la fórmula a base de leche de soya se prescribe para bebés que son intolerantes a la proteína de la leche (caseína).</p>
                <p>Yo sugeriría que si tu bebé no tiene problemas con&nbsp;la leche de&nbsp;fórmula normal, le proporciones ésta, ya que un bebé que nunca es expuesto a la lactosa (carbohidrato de la leche) puede hacerse intolerante a ésta. Además, hay fórmulas para bebés que tienen probióticos (microorganismos que ayudan a la flora intestinal y la buena digestión) y por tanto son excelentes para tu bebé.</p>
                <p>Puedes introducir la soya cuando tu bebé cumpla 8 meses a través de otros productos:&nbsp;platillos preparados con&nbsp;soya texturizada ó harina de soya.</p>
                <p>Si tienes cualquier otra duda, estaré contenta de resolverla.&nbsp;</p>


            </li>
                <li id="comment-6570" className="comment even thread-even depth-1">


                <b className="fn">veronica bernal</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6570/index.html">
                <time dateTime="2006-04-03T09:55:05+00:00">Lunes, abril 3, 2006 a las 9:55:AM</time>
            </a>



                <p>Hola Ilse</p>
                <p>Tengo a una prima que esta enferma de cancer.</p>
                <p>Primero le dió anemia y luego leucemia. Ha estado a punto de morir
            pero se ha salvado; a ella la van a dar de alta a lo mejor el martes de esta semana
            y ahorita anda muy baja de ánimos: le duele todo. ¿Que es recomendable
            cuando llege a su casa? ¿Cómo se puede alimentar bien? Ella necesita muchas vitaminas, algo que la levante.</p>
                <p>Espero tu respuesta, gracias</p>


            </li>
                <li id="comment-6571" className="comment odd alt thread-odd thread-alt depth-1">


                <b className="fn"><a href="http://www.ilsevalle.com" rel="external nofollow" className="url">Ilse</a></b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6571/index.html">
                <time dateTime="2006-04-03T14:16:16+00:00">Lunes, abril 3, 2006 a las 2:16:PM</time>
            </a>



                <p>Hola Verónica
            Imagino que tú y tu familia han de estar pasando un tiempo difícil. Le deseo
            el mejor de los pronósticos a tu prima.<br/>
            Cuando una persona está recibiendo quimioterapia (medicamentos para
                                                              combatir el cáncer) es difícil que acepten gran variedad de alimentos; además,
            por lo general tienen poca hambre.<br/>
            Lo que yo te recomiendo es darle un suplemento energético que
            contenga 500 K <b>calorías</b> y que además contenga vitaminas (hay varias
                                                                    marcas, y los puedes encontrar en el supermercardo en el área de
                                                                    farmacia).<br/>
            También es recomendable darle una dieta blanda en cuanto a la consistencia
            de los alimentos. No hay que darle carnes que sean difíciles de
            masticar, por lo que el pescado y el atún enlatado en agua son buenas opciones.
            Los quesos blandos, la leche descremada, los yogurt con bacilos que
            ayudan a la digestión también son una buena opción.<br/>
            Frutas sin cáscara como la papaya, el melón, el plátano, el mango son
            algunas frutas que le pueden dar.<br/>
            Para hacer más fácil la digestión delas verduras hay que cocerlas antes
            de que las coma.<br/>
            Muy importante es la higiene en la preparación de los alimentos, ya que
            tu prima tiene sus defensas bajas por el momento, y podría enfermar con
            cualquier cosa.<br/>
            Mucha suerte y saludos</p>


            </li>
                <li id="comment-6572" className="comment even thread-even depth-1">


                <b className="fn">CECY</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6572/index.html">
                <time dateTime="2006-04-11T12:21:13+00:00">Martes, abril 11, 2006 a las 12:21:PM</time>
            </a>



                <p>Hola, siempre he tenido la duda si la leche en polvo ayuda a controlar
            el peso, o al contrario, sube de peso; normalmente me gusta licuar<br/>
            dos cucharadas soperas de polvo de soya con un vaso de agua y fruta (una manzana)</p>
                <p>Gracias</p>


            </li>
                <li id="comment-6573" className="comment byuser comment-author-ilse bypostauthor odd alt thread-odd thread-alt depth-1">


                <b className="fn"><a href="http://ilsevalle.com" rel="external nofollow" className="url">Ilse</a></b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6573/index.html">
                <time dateTime="2006-04-12T10:13:07+00:00">Miércoles, abril 12, 2006 a las 10:13:AM</time>
            </a>



                <p>Hola Cecy</p>
                <p>La <b>leche en polvo o líquida, ya sea de soya o de vaca, tiene un contenido
            energético similar</b>, lo que significa que mientras no excedas la cantidad
            recomendada para preparar la leche en polvo (2 Cucharadas soperas),
            es exactamente lo mismo.<br/>
            Es importante resaltar que ningún alimento te baja o sube de peso por sí solo, sino es la cantidad
            de alimento y tipos de comida que consumes durante TODO el día lo
            que hace que subas o bajes de peso.<br/>
            Yo t recomiendo que consumas leche ya sea líquida o en polvo, de soya o vaca
            descremada.<br/>
            Suerte</p>


            </li>
                <li id="comment-6574" className="comment even thread-even depth-1">


                <b className="fn">comar nioanto</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6574/index.html">
                <time dateTime="2006-04-25T11:02:52+00:00">Martes, abril 25, 2006 a las 11:02:AM</time>
            </a>



                <p>Con la soya puedo ganar peso?
            Nesesito aumentar de peso y me gustaria saber como lo puedo lograr. Si alguien me puede ayudar le estaria muy agradecido.</p>


            </li>
                <li id="comment-6575" className="comment odd alt thread-odd thread-alt depth-1">


                <b className="fn"><a href="http://ilsevalle.com" rel="external nofollow" className="url">Ilse</a></b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6575/index.html">
                <time dateTime="2006-04-26T07:43:36+00:00">Miércoles, abril 26, 2006 a las 7:43:AM</time>
            </a>



                <p>Hola Comar<br/>
            Culaquier alimento (incluyendo la soya) te puede ayudar a subir de peso, todo depende de la cantidad. Yo te recomiendo que vayas con un nutriólogo para que te diseñe un plan de elimentación específico para tí, tomando en cuenta tus actividades y gustos. Además, tu dieta debe ser equilibrada para que no te descompenses.<br/>
            Saludos.</p>


            </li>
                <li id="comment-6576" className="comment even thread-even depth-1">


                <b className="fn">LUZ ALINA NUMA</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6576/index.html">
                <time dateTime="2006-04-27T08:15:03+00:00">Jueves, abril 27, 2006 a las 8:15:AM</time>
            </a>



                <p>Gracias Ilse, precisamente el costo es incorrecto. Serìa què arriba de 10 bolsas de a kilo, el costo serìa de $17.00 pesos. De antemano te agradezco el editar mi anuncio. Saludos</p>


            </li>
                <li id="comment-6577" className="comment odd alt thread-odd thread-alt depth-1">


                <b className="fn">Laura Gomez</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6577/index.html">
                <time dateTime="2006-04-28T19:44:06+00:00">Viernes, abril 28, 2006 a las 7:44:PM</time>
            </a>



                <p>Muchas gracias por este sitio.<br/>
            Y felicidades es en realidad muy interesante y util.
            Pero aun no se bien de el tema y deseo saber mas,
            mas que nada para poder alimentarme sanamente.
            Por lo tanto tengo algunas preguntas:
            Qué es el residuo de leche de soya?
            Dónde consigo estos productos? Se que dificilmente
            podria, conseguirlos y de ser asi, creo que me
            costaria mas que si yo viviera en Mexico,
            pues espero poder obtenerlos. De lo contrario
            me podrias indicar como prepararlos teniendo yo la
            materia prima, seria de muchisima ayuda.
            Tienes algun documento o informe que nos ensene
            de las propiedades y demás beneficios de consumir
            trigo, me refiero desde obtener el grano entero y
            crudo. En base a esto tambien tengo varias dudas.
            Me encantaria que me explicaras.<br/>
                -Sus propiedades.<br/>
                -De que manera es mas recomendable cocerlo, para
            que mantenga todas sus cualidades y beneficios.<br/>
                -Y maneras o recetas para prepararlo.</p>
                <p>De antemano muchas gracias.<br/>
            Se que tal vez es mucha molestia, pero estoy muy
            interesada. Vivo en Canada y a pesar de ser un pais
            “primermundista” le falta desarrollar muchos aspectos
            encuanto nutricion y mas que nada alimentacion
            sana. Y se que eso depende engran manera de mi,
            por eso acudo a ti Ilse.
            Hasta pronto</p>


            </li>
                <li id="comment-6578" className="comment even thread-even depth-1">


                <b className="fn">ronald gomez</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6578/index.html">
                <time dateTime="2006-05-03T07:38:35+00:00">Miércoles, mayo 3, 2006 a las 7:38:AM</time>
            </a>



                <p>quisiera saber la preparacion del chicharron de soya por
            favor</p>


            </li>
                <li id="comment-6579" className="comment odd alt thread-odd thread-alt depth-1">


                <b className="fn"><a href="http://example.com" rel="external nofollow" className="url">helvert ardila</a></b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6579/index.html">
                <time dateTime="2006-05-03T10:36:44+00:00">Miércoles, mayo 3, 2006 a las 10:36:AM</time>
            </a>



                <p>quisiera saber el proceso de la soya para la obtención industrial de la
            salsa soya</p>


            </li>
                <li id="comment-6580" className="comment even thread-even depth-1">


                <b className="fn">Magda Sofía Pérez</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6580/index.html">
                <time dateTime="2006-05-03T13:29:00+00:00">Miércoles, mayo 3, 2006 a las 1:29:PM</time>
            </a>



                <p>Hola Ilse.</p>
                <p>Leí acerca de alimetar a los bebes con leche de soya, mi pregunta es. Puedo alternarle a mi bebé de ocho meses la leche de soya, hecha en casa, con la formula normal, distribuidos así, 1 pepe de formula (no soya) y 2 pepes de leche de soya, ademas de las comidas del día?, agradeceré mucho tu respuesta.</p>
                <p>Atentamente.</p>
                <p>Magda Sofía Pérez.
            Tegucigalpa, Honduras.</p>


            </li>
                <li id="comment-6581" className="comment odd alt thread-odd thread-alt depth-1">


                <b className="fn"><a href="http://example.com" rel="external nofollow" className="url">GABY</a></b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6581/index.html">
                <time dateTime="2006-05-03T16:57:38+00:00">Miércoles, mayo 3, 2006 a las 4:57:PM</time>
            </a>



                <p>deseo saber la composicion de aminoacidos de la soya</p>


            </li>
                <li id="comment-6582" className="comment even thread-even depth-1">


                <b className="fn">sigrid</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6582/index.html">
                <time dateTime="2006-05-05T06:33:02+00:00">Viernes, mayo 5, 2006 a las 6:33:AM</time>
            </a>



                <p>hola , les escribo de Mérida ,Venezuela.<br/>
            Mi pregunta es; si la soya es benéfica, por qué se continúa su consumo
            a pesar de ser transgénica, realmente no entiendo, además he leído
            del peligro de sus derivados, pues no existe evidencia de lo que pudiera
            suceder hasta el momento. En ocasiones ha salido artículos de prensa
            advitriendo NO consumir la soya por las razones ya expuestas anteriormente.
            gracias.Sigrid<br/>
            Nota: tengo mucha duda.</p>


            </li>
                <li id="comment-6583" className="comment odd alt thread-odd thread-alt depth-1">


                <b className="fn">Jenny</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6583/index.html">
                <time dateTime="2006-05-08T18:55:27+00:00">Lunes, mayo 8, 2006 a las 6:55:PM</time>
            </a>



                <p>Hola! Estoy interesada en cómo se saca el polvo de la soya; es decir,
            el proceso por el cuál la soya debe pasar para llegar a ser polvo,
            granulado, y todos los resultados que despues ya solo se preparan
            y se cocinan de una manera simple. Apreciaría mucho su ayuda,
            gracias.</p>


            </li>
                <li id="comment-6584" className="comment even thread-even depth-1">


                <b className="fn">juan</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6584/index.html">
                <time dateTime="2006-05-09T13:14:11+00:00">Martes, mayo 9, 2006 a las 1:14:PM</time>
            </a>



                <p>Me encanta esta pagina de la soya!</p>
                <p>Yo la consumo: hago leche y tortas.
            Agradeceria si alguien me puede decir como hacer crepas, pues no he podido hacerlas: se me derrama todo.
            Muchas gracias</p>


            </li>
                <li id="comment-6585" className="comment odd alt thread-odd thread-alt depth-1">


                <b className="fn">SARA ANTONIA</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6585/index.html">
                <time dateTime="2006-05-11T12:58:16+00:00">Jueves, mayo 11, 2006 a las 12:58:PM</time>
            </a>



                <p>Hola</p>
                <p>Mil gracias por sus recetas de verdad que con ellas no me cuesta
            trabajo enfocar mi dieta completamente en la soya, pero de igual manera
            les pido envien más y más recetas con soya.
            Gracias!!!!</p>


            </li>
                <li id="comment-6586" className="comment even thread-even depth-1">


                <b className="fn"><a href="http://www.ilsevalle.com" rel="external nofollow" className="url">Ilse</a></b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6586/index.html">
                <time dateTime="2006-05-12T19:09:18+00:00">Viernes, mayo 12, 2006 a las 7:09:PM</time>
            </a>



                <p>Hola Laura Gómez<br/>
            Cuando elaboras leche de soya en casa, necesitas remojar las semillas de soya en agua durante algún tiempo; posteriormente, se licúa y finalmente se cuela. El líquido es la leche de soya y el residuo sólido es a lo que llamamos “residuo de la leche de soya”.<br/>
            No tengo información de dónde puedes condeguir estos productos en tu país de residencia Canadá, pero imagino que puedes revisar los supermercados y tiendas de productos orgánicos… ya que busqué en internet tiendas donde vendan productos de soya en Canadá y hay mucha información al respect.<br/>
            Para poder preparar productos con las semillas de soya, te recomiendo que busques recetas caseras en internet. Mi área es la clínica, y no estoy muy involucrada en el área industrial. Pero estas páginas pueden ayudarte en caunto a recetas:<br/>
                <a href="http://www.talksoy.com/Recipes/default.htm" rel="nofollow">talksoy.com/</a><br/>
                <a href="http://www.thumboilseed.com/recipes.htm" rel="nofollow">thumboilseed.com</a><br/>
            No debes preocuparte mucho acerca de que la soya pierda sus propiedades, ya que no se destruyen con el calor o con la preparación de otros productos derivados; lo que te puedo aconsejar, es que si encuentras productos de soya, que compres productos que han sido fermentados para su elaboración, tales como miso, tempeh, tofu y leche de soya. La fermentación prolongada destruye las toxinas e incrementa su digestibilidad.<br/>
            Evita productos que han sido demasiado procesados, tales como los embutidos..<br/>
            El truco para una buena nutrición es hacer tu dieta variada; esto significa que no consumas productos de soya exclusivamente o como platillo principal, sino como un complemento a tu dieta.<br/>
            El trigo no tiene las mismas propiedades que la soya, pero también es un alimento nutritivo; ya que contiene fibra soluble e insoluble.<br/>
            Saludos.</p>


            </li>
                <li id="comment-6587" className="comment odd alt thread-odd thread-alt depth-1">


                <b className="fn"><a href="http://www.ilsevalle.com" rel="external nofollow" className="url">Ilse</a></b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6587/index.html">
                <time dateTime="2006-05-12T19:16:18+00:00">Viernes, mayo 12, 2006 a las 7:16:PM</time>
            </a>



                <p>Hola Ronald Gómez<br/>
            Fíjate que mi área es la clínica, no la industrial de preparación de alimentos; desconosco la preparación del chicharrón de soya; pero me parece que es un proceso industrial y no casero. Dejo tu comentario por si alguien conoce la receta para preparar chicharrón de soya casero.<br/>
            Saludos.</p>


            </li>
                <li id="comment-6588" className="comment even thread-even depth-1">


                <b className="fn"><a href="http://www.ilsevalle.com" rel="external nofollow" className="url">Ilse</a></b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6588/index.html">
                <time dateTime="2006-05-12T19:20:04+00:00">Viernes, mayo 12, 2006 a las 7:20:PM</time>
            </a>



                <p>Hola Helvert<br/>
            Lamento no ser de mucha ayuda, ya que parece que te urge saber el proceso para la elaboración de la salsa de soya. Mi área es la clínica, no la industrial.<br/>
            Dejo tu mensaje por si alguien sabe acerca del proceso y te puede ayudar.<br/>
            Mucha suerte y saludos.</p>


            </li>
                <li id="comment-6589" className="comment odd alt thread-odd thread-alt depth-1">


                <b className="fn"><a href="http://www.ilsevalle.com" rel="external nofollow" className="url">Ilse</a></b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6589/index.html">
                <time dateTime="2006-05-12T19:24:21+00:00">Viernes, mayo 12, 2006 a las 7:24:PM</time>
            </a>



                <p>Hola Magda<br/>
            Acerca de alimentar a tu bebé con fórmula de soya elaborada en casa:<br/>
            Me parece excelente lo que haces. La soya se debe introducir a la alimentación de los bebés a partir de los 8 meses (como tu bebé); pero no debe ser es único tipo de alimento que recibe, asíq eu es muy bueno que varíes los alimentos que le proporcionas a tu bebé.<br/>
            Me da mucho gusto que te interese tanto la alimentación de tu bebé; los hábitos se forman desde niños así que vas por buen camino.<br/>
            Saludos.</p>


            </li>
                <li id="comment-6590" className="comment even thread-even depth-1">


                <b className="fn"><a href="http://www.ilsevalle.com" rel="external nofollow" className="url">Ilse</a></b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6590/index.html">
                <time dateTime="2006-05-12T19:30:22+00:00">Viernes, mayo 12, 2006 a las 7:30:PM</time>
            </a>



                <p>Hola Gaby
            Aquí te incluyo una lista con los aminoácidos contenidos en la soya (son todos los esenciales para el ser humano):
            Isoleucina<br/>
            Leucina<br/>
            Lisina<br/>
            Metionina<br/>
            Cistina<br/>
            Fenilalanina<br/>
            Tirosina<br/>
            Treonina<br/>
            Triptóphano<br/>
            Valina </p>
                <p>Saludos.</p>


            </li>
                <li id="comment-6591" className="comment odd alt thread-odd thread-alt depth-1">


                <b className="fn"><a href="http://www.ilsevalle.com" rel="external nofollow" className="url">Ilse</a></b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6591/index.html">
                <time dateTime="2006-05-12T19:41:28+00:00">Viernes, mayo 12, 2006 a las 7:41:PM</time>
            </a>



                <p>Hola Sigrid.<br/>
            Me parece muy interesante y valioso tu comentario. Un alimento transgénico es aquel que ha sido modificado genéticamente; esto no significa que sea malo necesariamente.<br/>
            El propio maíz es transgénico y fue resultado de la propia naturaleza; las semillas se pueden mezclar, y se van modificando genéticamente a través de los años.<br/>
            Muchos de los artículos que mencionas (en contra de la soya) es porque no se debe consumir en exceso NINGÚN alimento: de ahí la importancia de una dieta variada. Muchas vecespasa que un alimento en específico se pone de “moda” y se consume en desayuno, comida y cena. Esto no debe ser, ya que todo, absolutamente TODO tiene toxicidad, pero esta depende de su frecuencia y cantidad.<br/>
            De ahí la importncia de orientarse con un nutriólogo para tener orientación de como comer de manera variada, higiénica, suficiente, completa y equilibrada.<br/>
            Saludos y espero haber sido de ayuda.</p>


            </li>
                <li id="comment-6592" className="comment even thread-even depth-1">


                <b className="fn"><a href="http://www.ilsevalle.com" rel="external nofollow" className="url">Ilse</a></b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6592/index.html">
                <time dateTime="2006-05-12T19:46:15+00:00">Viernes, mayo 12, 2006 a las 7:46:PM</time>
            </a>



                <p>Hola Jenny.
            El proceso por el cual se elabora el polvo de soya es industrial, y se necesita maquinaria específica para ello. Hay una variedad de productos de soya que puedes encontrar en el supermercado si es qeu te interesa consumir distintos productos a base de soya.<br/>
            Si alguien más sabe acerca de este proceso, porfavor ayúden a resolver esta pregunta.<br/>
            Saludos.</p>


            </li>
                <li id="comment-6593" className="comment odd alt thread-odd thread-alt depth-1">


                <b className="fn"><a href="http://www.ilsevalle.com" rel="external nofollow" className="url">Ilse</a></b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6593/index.html">
                <time dateTime="2006-05-12T19:48:58+00:00">Viernes, mayo 12, 2006 a las 7:48:PM</time>
            </a>



                <p>Hola Juan.
            Desconosco recetas para crepas de soya, pero dejo tu pregunta por si alguine nos ayuda con este tema.
            Saludos y gracias por tu comentario.</p>


            </li>
                <li id="comment-6594" className="comment even thread-even depth-1">


                <b className="fn"><a href="http://www.ilsevalle.com" rel="external nofollow" className="url">Ilse</a></b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6594/index.html">
                <time dateTime="2006-05-12T19:51:03+00:00">Viernes, mayo 12, 2006 a las 7:51:PM</time>
            </a>



                <p>Hola Sara Antonia.
            Gracias por tu comentario tan positivo. Me parece excelente que trates de hacer muy nutritiva&nbsp;tu&nbsp;dieta… la salud es lo más importante.<br/>
            Sólo recuerda que la soya debe ser un complemento para tu dieta y no debes basarte en alimentos de soya unicamente: tiene que haber variedad.<br/>
            Saludos y suerte.</p>


            </li>
                <li id="comment-6595" className="comment odd alt thread-odd thread-alt depth-1">


                <b className="fn">EVELYN</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6595/index.html">
                <time dateTime="2006-05-13T14:06:55+00:00">Sábado, mayo 13, 2006 a las 2:06:PM</time>
            </a>



                <p>TENGO EL HIGADO GRASO ME AYUDARA LA SOYA CON LA DIETA DE NO CO
            COMER LAS CARNES ROJAS, ARROZ, PAN BLANCO.</p>


            </li>
                <li id="comment-6596" className="comment even thread-even depth-1">


                <b className="fn"><a href="http://ilsevalle.com" rel="external nofollow" className="url">Ilse</a></b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6596/index.html">
                <time dateTime="2006-05-16T10:05:08+00:00">Martes, mayo 16, 2006 a las 10:05:AM</time>
            </a>



                <p>Hola Evelyn</p>
                <p>Sí te ayudaría la soya, así como consumir una dieta alta en fibra; mencionas que no consumes carnes rojas, ni arroz, ni pan blanco. Es importante consumir alimentos integrales, tales como arroz integral ó con cáscara, cereales integrales, pastas integrales, pan de caja integral, salvado de trigo, amaranto y avena.<br/>
            Recuerda la importancia de consumir una dieta variada y equilibrada.</p>
                <p>Saludos.</p>


            </li>
                <li id="comment-6597" className="comment odd alt thread-odd thread-alt depth-1">


                <b className="fn">ERIKA AVILEZ</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6597/index.html">
                <time dateTime="2006-05-16T16:16:31+00:00">Martes, mayo 16, 2006 a las 4:16:PM</time>
            </a>



                <p>ME GUSTARIA TENER RECETAS DEL FRIJOL DE SOYA</p>


            </li>
                <li id="comment-6598" className="comment even thread-even depth-1">


                <b className="fn">Valdi</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6598/index.html">
                <time dateTime="2006-05-18T15:57:23+00:00">Jueves, mayo 18, 2006 a las 3:57:PM</time>
            </a>



                <p>Hola que tal la pagina esta muy bien, me gusto mucho y conoci mucho
            de la soya, me gustaria que alguien me pudiera dar una receta para elaborar
            jamon de soya y salchicha de soya a traves de soya texturizada.<br/>
            Por su atencion Muchas Gracias, por cierto Ilse te felicito se ve que eres una excelente nutriologa.</p>


            </li>
                <li id="comment-6599" className="comment odd alt thread-odd thread-alt depth-1">


                <b className="fn">Emmanuel Trujillo</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6599/index.html">
                <time dateTime="2006-05-23T10:59:14+00:00">Martes, mayo 23, 2006 a las 10:59:AM</time>
            </a>



                <p>Hola Ilse, un favorsote, me podrías decir como son los pasos para la
            elaboración de la salsa de soya que se usa en la comida oriental?
            que pasos estan en la fermentación? y cantidades de proteinas, grasas
            su valor nutricional? por favor, es para una esposicion de la escuela
            que tengo que realizar en unos días y no he emcontrado nada al
            respecto de la elaboración y valor nutricional de la salsa de soya.
            GRACIAS</p>


            </li>
                <li id="comment-6600" className="comment even thread-even depth-1">


                <b className="fn">marita</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6600/index.html">
                <time dateTime="2006-05-29T06:40:34+00:00">Lunes, mayo 29, 2006 a las 6:40:AM</time>
            </a>



                <p>hola: a mi hijo de 3 años le puedo cambiar de leche evaporada
            a solo leche de soya. o le puedo mezclar las dos leches que diferencias
            hay entre las dos leches.
            Agradeceria bastante su ayuda.
            Muchas gracias</p>


            </li>
                <li id="comment-6601" className="comment odd alt thread-odd thread-alt depth-1">


                <b className="fn">luz maria</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6601/index.html">
                <time dateTime="2006-06-03T11:12:25+00:00">Sábado, junio 3, 2006 a las 11:12:AM</time>
            </a>



                <p>hola felicidades por esta pagina quisiera tener variedad de recetas para preparar con soya gacias</p>


            </li>
                <li id="comment-6602" className="comment even thread-even depth-1">


                <b className="fn">GABY</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6602/index.html">
                <time dateTime="2006-06-13T10:03:42+00:00">Martes, junio 13, 2006 a las 10:03:AM</time>
            </a>



                <p>Hola espero te encuentres bien tengo una duda <b>cuantas calorias</b> tiene el germinadi de soya no se por cantidad por ejemplo 100 gm</p>


            </li>
                <li id="comment-6603" className="comment odd alt thread-odd thread-alt depth-1">


                <b className="fn"><a href="http://www.ilsevalle.com" rel="external nofollow" className="url">Ilse</a></b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6603/index.html">
                <time dateTime="2006-06-14T17:50:11+00:00">Miércoles, junio 14, 2006 a las 5:50:PM</time>
            </a>



                <p>Hola Marita.
            Los niños de 3 años no es necesario qeu consuman leche evaporada, ya que la concentración de grasas y carbohidratos es el doble que en la leche normal. La leche de soya está indicada si tu hijo tiene alguna intolerancia ala proteína de la leche de soya, lo cual no mencionaste.<br/>
            Yo sugeriría que tu hijo consumiera leche de soya descremada o leche de vadca descremada, ya que nadie requiere la cantidad de grasas saturadas que tiene la leche entera.<br/>
            Si requieres más información acerca de la alimentación que requiere tu hijo, te sugiero qeu acudas con un nutriólogo para que dependiendo de sus requerimientos específicos, te de recomendaciones y elabore una dieta adecuada para él.<br/>
            Saludos.</p>


            </li>
                <li id="comment-6604" className="comment even thread-even depth-1">


                <b className="fn"><a href="http://www.ilsevalle.com" rel="external nofollow" className="url">Ilse</a></b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6604/index.html">
                <time dateTime="2006-06-14T18:02:23+00:00">Miércoles, junio 14, 2006 a las 6:02:PM</time>
            </a>



                <p>Hola Gaby.
            Aquí te pongo el contenido nutrimental del germinado de soya por cada 100 g:<br/>
            energía=76 kcal<br/>
            proteínas=8 g<br/>
            grasas=4.2 g<br/>
            carbohidratos=6.2 g<br/>
            Saludos.</p>


            </li>
                <li id="comment-6605" className="comment odd alt thread-odd thread-alt depth-1">


                <b className="fn"><a href="http://www.ilsevalle.com" rel="external nofollow" className="url">Ilse</a></b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6605/index.html">
                <time dateTime="2006-06-14T18:08:40+00:00">Miércoles, junio 14, 2006 a las 6:08:PM</time>
            </a>



                <p>Hola Emmanuel.<br/>
            Te pido una disculpa porque no sé si aún esté a tiempo de ayudarte
            antes de tu exposición. Te ayudo con lo del contenido nutrimental de
            la soya, pero en cuanto a los pasos para su elaboración, la verdad eso
            es más del área industrial y yo soy de área clínica.<br/>
            Contenido nutrimental de salsa de soya por 1 cucharadita (5 g):<br/>
            energía=2 kcal<br/>
            proteínas=0.4 g<br/>
            grasas=0 g<br/>
            carbohidratos=0.2 g<br/>
            sodio=340 mg<br/>
            Saludos.</p>


            </li>
                <li id="comment-6606" className="comment even thread-even depth-1">


                <b className="fn">ale</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6606/index.html">
                <time dateTime="2006-06-21T17:24:21+00:00">Miércoles, junio 21, 2006 a las 5:24:PM</time>
            </a>



                <p>Hola es la primera vez que entro a este sitio y me pareció muy
            interesante leer sus inquietudes.</p>
                <p>Yo tengo gastritis y colitis y me gustaría saber sobre algunos alimentos
            que pueden favorecer mi flora intestinal y saber recteas para cocinar la soya.</p>
                <p>Gracias.</p>


            </li>
                <li id="comment-6607" className="comment odd alt thread-odd thread-alt depth-1">


                <b className="fn">karime</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6607/index.html">
                <time dateTime="2006-06-22T22:59:39+00:00">Jueves, junio 22, 2006 a las 10:59:PM</time>
            </a>



                <p>Hola, felicidades por tu página está super interesante.
            hoy compré soya texturizada por primera vez y entré a internet para
            saber como prepararla, por cierto que aprendí muchísimo al respecto,
            para todas las personas que preguntan recetas pueden hacer cualquier
            receta que conozcan bien y sustituir la carne por la soya remojada y
            escurrida (remojada en caldo tibio de pollo o verduras le da mejor sabor).
            Por otra parte conoces algo del extracto foliar? Parece ser un suplemento
            alimenticio muy bueno para las enfermedades como la anemia y la
            desnutrición yo encontré algo en <a href="http://www.soynica.org.ni" rel="nofollow">soynica</a> por si alguien está
            interesado. Gracias</p>


            </li>
                <li id="comment-6608" className="comment even thread-even depth-1">


                <b className="fn">Guillermina Sales</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6608/index.html">
                <time dateTime="2006-07-05T11:00:26+00:00">Miércoles, julio 5, 2006 a las 11:00:AM</time>
            </a>



                <p>Hola Ilse:<br/>
            Me da mucho gusto haber encontrado tu página porque comparto
            contigo el gusto por la soya ya que tengo muchos años consumiéndola.
            Yo también soy nutrióloga especializada en la alimentación vegetariana
            y mi hijo de 11 años es vegetariano desde el nacimiento. He aprendido
            a través del tiempo a equilibrar su alimentación sustituyendo las proteínas
            animales por proteínas vegetales, principalmente a partir de las leguminosas
            como la soya. He tenido muy buenos resultados y quiero compartir
            contigo y tus lectores mi experiencia. Al principio fué difícil porque no
            tenía recetas y me pasaba horas en la cocina inventando y
            probando recetas. Pero gracias a la variedad de frutas, verduras, leguminosas,
            oleaginosas y granos que tenemos en nuestro país (además del huevo y lácteos)
            aprendí a diseñar una dieta vegetariana exitosa. La soya me ayudó mucho
            para sustituir platillos con carne.<br/>
            Ahora me dedico a dar talleres de nutrición y cocina vegetariana en Querétaro
            en donde enseño la preparación de la leche de soya, tofu y platillos con soya
            en general. Escribí un manual de cocina vegetariana con 31 menús
            vegetarianos variados que manejo en mis talleres.<br/>
            Agradezco la oportunidad que tienes en tu sitio para que las personas
            interesadas puedan conocer los beneficios de comer soya y comentar sus
            experiencias. Estoy a tus órdenes, saludos.</p>


            </li>
                <li id="comment-6609" className="comment byuser comment-author-ilse bypostauthor odd alt thread-odd thread-alt depth-1">


                <b className="fn"><a href="http://ilsevalle.com" rel="external nofollow" className="url">Ilse</a></b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6609/index.html">
                <time dateTime="2006-07-10T17:08:22+00:00">Lunes, julio 10, 2006 a las 5:08:PM</time>
            </a>



                <p>Hola Ale.<br/>
            Hay una gran cantidad de aliemtnos que favorecen tu flora intestinal.<br/>
            Tienes que buscar productos que tienen probióticos (microorganismo qeu favorecen tu flora intestinal) y prebióticos (el alimetnos de los probióticos).<br/>
            Entre estos alimetos se encuentran los yogurts que tengan bifidus,
            también están las leches que contien fibras (lactofibras y actifibras) y el jocoque.<br/>
            Otra buena práctica que mejora la gastritis y colitis es tener horarios establecidos para comer y nunca saltarse tiempos de comida, NUNCA.<br/>
            Algunas especias y tés mejoran la digestión: tomillo, mejorana, manzanilla, 12 flores, naranjo.<br/>
            Saludos.</p>


            </li>
                <li id="comment-6610" className="comment byuser comment-author-ilse bypostauthor even thread-even depth-1">


                <b className="fn"><a href="http://ilsevalle.com" rel="external nofollow" className="url">Ilse</a></b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6610/index.html">
                <time dateTime="2006-07-10T17:17:47+00:00">Lunes, julio 10, 2006 a las 5:17:PM</time>
            </a>



                <p>Hola Karime.<br/>
            Estoy segura de que muchas personas vana a agradecer que hayas
            especificado qeu muchas de als recetas tradicionales se pueden preparar igual, pero sustituyendo la carne por soya texturizada; realmente no hay mucha ciencia ni dificultad para
            poder incluír la soya dentro de la alimentación diaria.<br/>
            En cuanto al extracto foliar, sí es muy interesante, pero sin embargo yo
            creo que llevando una alimentación suficiente en energía, higiénica, equilibrada,
            completa y variada puedes consumir todos los nutrimentos que necesitas sin recurrir a suplementos.<br/>
            La página que pones está interesante, pero no hay que olvidar que la
            información está dirigida a Nicaragua.
            Saludos.</p>


            </li>
                <li id="comment-6611" className="comment byuser comment-author-ilse bypostauthor odd alt thread-odd thread-alt depth-1">


                <b className="fn"><a href="http://ilsevalle.com" rel="external nofollow" className="url">Ilse</a></b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6611/index.html">
                <time dateTime="2006-07-10T17:24:34+00:00">Lunes, julio 10, 2006 a las 5:24:PM</time>
            </a>



                <p>Hola Guillermina.<br/>
            Me parece muy interesante tu comentario. Es bonito ver como la buena
            nutrición te cambia la vida y tus buenos hábitos.<br/>
            Me parece maravilloso que tengas un trabajo acerca de preparación de comida a base de soya. Como habrás podido ver, muchos de los lectores de esta página están interesados en menús a base de soya.<br/>
            ¿Podrías mandar tus datos para cualquier persona que estuviera interesada en comprar tu manual o bien, en asistir a tus talleres? No sé si hay manera de que lo adquieran por medio de internet.<br/>
            Asismismo, me encantaría tener un contacto en Querétaro. Yo me he sorprendido de la cantidad de personas en Querétaro que están interesadas en la nutrición.<br/>
            Gracias y cualquier cosa, puedes escribirme también a mi mail <Mailto email="ilse@ilsevalle.com" /><br/>
            Saludos y mucho éxito.</p>


            </li>
                <li id="comment-6612" className="comment even thread-even depth-1">


                <b className="fn"><a href="http://example.com" rel="external nofollow" className="url">Wilson Flanders</a></b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6612/index.html">
                <time dateTime="2006-07-21T22:09:59+00:00">Viernes, julio 21, 2006 a las 10:09:PM</time>
            </a>



                <p>I really enjoyed looking at your site, I found it very helpful indeed, keep up the good work.</p>


            </li>
                <li id="comment-6613" className="comment odd alt thread-odd thread-alt depth-1">


                <b className="fn">Lisset Silva</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6613/index.html">
                <time dateTime="2006-07-22T17:03:57+00:00">Sábado, julio 22, 2006 a las 5:03:PM</time>
            </a>



                <p>Tengo un niño de dos años y quisiera saber cual es la cantidad diaria que puede consumir leche de soya ,preparada por mi del frejol seco, teniendo en cuenta que me gustaría alternar con la leche entera de vaca.<br/>
            Gracias por tu respuesta</p>


            </li>
                <li id="comment-6614" className="comment even thread-even depth-1">


                <b className="fn"><a href="http://www.ilsevalle.com" rel="external nofollow" className="url">Ilse</a></b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6614/index.html">
                <time dateTime="2006-08-12T12:48:25+00:00">Sábado, agosto 12, 2006 a las 12:48:PM</time>
            </a>



                <p>Hola Lisset Silva<br/>
            Para cada caso hay que saber muchos datos más y lo que me dices es sólo la edad de tu niño lo que te puedo dar son recomendaciones muy generales, pero es necesario hacer una evaluación en consulta con un nutriólogo para que te de el requerimiento exacto de tu niño.<br/>
            Si vas a utilizar leche de vaca en la dieta de tu niño sugiero que sea descremada y que además incluyas una porción de leche de soya.<br/>
            Saludos</p>


            </li>
                <li id="comment-6615" className="comment odd alt thread-odd thread-alt depth-1">


                <b className="fn">Jorge Mendoza</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6615/index.html">
                <time dateTime="2006-08-14T10:46:42+00:00">Lunes, agosto 14, 2006 a las 10:46:AM</time>
            </a>



                <p>Hola,</p>
                <p>Tengo una pregunta, a ver quien me puede contestar. Quiero
            bacilos búlgaros para hacer leche fermentada o yoghourt, quien
            me puede dar unos pocos. Vivo en Querétaro, como no los he
            podido conseguir estoy dispuesto a viajar un poco. Gracias.</p>
                <p>Saludos</p>


            </li>
                <li id="comment-6616" className="comment even thread-even depth-1">


                <b className="fn">Vìctor López</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6616/index.html">
                <time dateTime="2006-08-14T11:23:32+00:00">Lunes, agosto 14, 2006 a las 11:23:AM</time>
            </a>



                <p>Hola. de antemano les agradezco la informacion que encontre.</p>
                <p>soy el administrador de una fundacion con trabajo social en barrios
            de extrema pobreza en Medellin, Colombia.
            uno de nuestros programas es un complemento nutricional para los
            niños y niñas que atendemos en el comedor comunitario.
            queremos innovar con productos de facil preparacion y consumo
            de niños menores de 5 años, como panelitas con soya, yogurt,
            arepas con queso de soya u otros productos.
            si nos pueden colaborar con recetas y certificados de nutricion
            se los agradeceremos. gracias</p>


            </li>
                <li id="comment-6617" className="comment odd alt thread-odd thread-alt depth-1">


                <b className="fn"><a href="http://www.ilsevalle.com" rel="external nofollow" className="url">Ilse</a></b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6617/index.html">
                <time dateTime="2006-08-14T14:01:49+00:00">Lunes, agosto 14, 2006 a las 2:01:PM</time>
            </a>



                <p>Hola Jorge Mendoza<br/>
            Espero que alguien cercano a ti tenga búlgaros… porqeu sí son difíciles de conseguir. En mi experiencia, amigas de personas mayores a 55 años suelen tener en su casa. Otra sugerencia sería ir a un mercado sobre ruedas y ver si alguien de ahí los vende o conoce a alguien que te los pueda conseguir.<br/>
            Suerte</p>


            </li>
                <li id="comment-6618" className="comment even thread-even depth-1">


                <b className="fn"><a href="http://www.ilsevalle.com" rel="external nofollow" className="url">Ilse</a></b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6618/index.html">
                <time dateTime="2006-08-14T14:03:06+00:00">Lunes, agosto 14, 2006 a las 2:03:PM</time>
            </a>



                <p>Hola Victor López<br/>
            Espero que tengas mucha suerte ya que tu labor parece ser muy prestigiada y de alto valor emocianal y con muchos valores.<br/>
            Saludos</p>


            </li>
                <li id="comment-6619" className="comment odd alt thread-odd thread-alt depth-1">


                <b className="fn">Abrajam</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6619/index.html">
                <time dateTime="2006-08-15T18:38:39+00:00">Martes, agosto 15, 2006 a las 6:38:PM</time>
            </a>



                <p>hola,</p>
                <p>este web esta super bien, la verdad muy informativo</p>
                <p>es por eso que acudo a ustedes, alguno sabra como se hace la salsa de soya?</p>
                <p>espero su respuesta</p>
                <p>gracias</p>


            </li>
                <li id="comment-6620" className="comment even thread-even depth-1">


                <b className="fn">LORENA</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6620/index.html">
                <time dateTime="2006-08-16T07:37:35+00:00">Miércoles, agosto 16, 2006 a las 7:37:AM</time>
            </a>



                <p>Hola. Felicidades por esta excelente página.
            A mi me gustaria perder peso, pero no estoy segura de si comiendo
            productos de soya texturizada me ayuadará a hacerlo, o por el
            contrario hará que lo gane. Te agradezco la ayuda que puedas
            proporcionarme.</p>


            </li>
                <li id="comment-6621" className="comment odd alt thread-odd thread-alt depth-1">


                <b className="fn"><a href="http://www.ilsevalle.com" rel="external nofollow" className="url">Ilse</a></b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6621/index.html">
                <time dateTime="2006-08-16T18:40:01+00:00">Miércoles, agosto 16, 2006 a las 6:40:PM</time>
            </a>



                <p>Hola Abrajam.<br/>
            Ya había buscado la información acerca de la producción de salsa de
            soya, pero no tuve éxito. Espero que alguien nos pueda ayudar en este respecto.<br/>
            Saludos.</p>


            </li>
                <li id="comment-6622" className="comment even thread-even depth-1">


                <b className="fn"><a href="http://www.ilsevalle.com" rel="external nofollow" className="url">Ilse</a></b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6622/index.html">
                <time dateTime="2006-08-16T18:43:20+00:00">Miércoles, agosto 16, 2006 a las 6:43:PM</time>
            </a>



                <p>Hola Lorena<br/>
            Pues lo que te ayuda a bajar de peso no es si consumes productos de soya o no, sino la cantidad que consumas y hay que toamr en cuenta los demás alimentos que van a estar incluídos en tu alimentación. Es por eso que es necesario que acudas con un nutriólogo para que te haga las valoraciones necesarias y te de recomendaciones específicas para tí. Recuerda también que una dieta basada puramente en soya, es altamente alergénica y no lo más recomendable.<br/>
            Muy importante: no existen alimentos que bajen de peso; la única<br/>
            manrera de quemar grasa es con el ejercicio y consumiendo una menor cantidad de energía que la que ocupas.<br/>
            Saludos y suerte.</p>


            </li>
                <li id="comment-6623" className="comment odd alt thread-odd thread-alt depth-1">


                <b className="fn">PAULO PEREZ</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6623/index.html">
                <time dateTime="2006-08-23T20:16:28+00:00">Miércoles, agosto 23, 2006 a las 8:16:PM</time>
            </a>



                <p>Hola, que interesante lo que comentas, mi pregunta es; se puede elaborar tofu
            a partir de la leche de soya que encuentras en el supermercado o es
            necesario elaborarlo como lo recomiendas en la receta.
            gracias, un abrazo</p>


            </li>
                <li id="comment-6624" className="comment even thread-even depth-1">


                <b className="fn">Ana</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6624/index.html">
                <time dateTime="2006-08-25T18:16:54+00:00">Viernes, agosto 25, 2006 a las 6:16:PM</time>
            </a>



                <p>hola,me gustaria tener mas recetas sobre la soya de frijolya que a mi
            mama le gusta eleborar la leche y con el gabaso tortitas y le
            encantaria hacer mas cosas con este alimento
            les agradeceria q nos proporcionaran algunas recetas sobre este
            alimento
            gracias,un abrazo</p>


            </li>
                <li id="comment-6625" className="comment odd alt thread-odd thread-alt depth-1">


                <b className="fn">irene</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6625/index.html">
                <time dateTime="2006-08-26T12:43:31+00:00">Sábado, agosto 26, 2006 a las 12:43:PM</time>
            </a>



                <p>Hola te escribo dedsde Uruguay tengo un nieto en EE UU y lo crian a leche de soya quisierasaber si tiene alguna contraindicacion ya que se enferma con mucha frecuencia Desde ya agradezco tu informacion</p>


            </li>
                <li id="comment-6626" className="comment even thread-even depth-1">


                <b className="fn"><a href="http://www.ilsevalle.com" rel="external nofollow" className="url">Ilse</a></b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6626/index.html">
                <time dateTime="2006-08-27T10:53:28+00:00">Domingo, agosto 27, 2006 a las 10:53:AM</time>
            </a>



                <p>Hola Paulo perez
            Yo nunca he intentado hacer el Tofu a partir de la leche de soya del supermercado, pero puedes inentarlo y ver si sale bien.
            Saludos y suerte</p>


            </li>
                <li id="comment-6627" className="comment odd alt thread-odd thread-alt depth-1">


                <b className="fn"><a href="http://www.ilsevalle.com" rel="external nofollow" className="url">Ilse</a></b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6627/index.html">
                <time dateTime="2006-08-27T10:55:56+00:00">Domingo, agosto 27, 2006 a las 10:55:AM</time>
            </a>



                <p>Hola Ana<br/>
            Espero también que manden más recetas a base de soya. Mientras puedes visitar el siguiente sitio:<br/>
                <a href="http://www.elplacerdeinvitar.com.ar/01_cocina/013100_soja.html" rel="nofollow">http://www.elplacerdeinvitar.com.ar</a>
            Saludos</p>


            </li>
                <li id="comment-6628" className="comment even thread-even depth-1">


                <b className="fn"><a href="http://www.ilsevalle.com" rel="external nofollow" className="url">Ilse</a></b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6628/index.html">
                <time dateTime="2006-08-27T10:58:38+00:00">Domingo, agosto 27, 2006 a las 10:58:AM</time>
            </a>



                <p>Hola Irene<br/>
            No mencionas la edad de tu nieto, ni cuanto tiempo lo amamantaron con leche materna.<br/>
            la razón de por qué se enferma tanto puede ser por diversas índoles. Si el pediatra aconsejó leche de soya porque tal vez era intolerante a las prote´nas de leche de vaca, es la mejor opción. También hay qu tomar en cuenta si consume otros alimentos además de la leche de soya o es exclusiva su alimentación a base de este producto. También puede ser que no lo expongan al ambiente exterior lo suficiente y sea por eso que ssus defensas no hayan alcanzado su máximo.<br/>
            Saludos</p>


            </li>
                <li id="comment-6629" className="comment odd alt thread-odd thread-alt depth-1">


                <b className="fn">Margarito Pérez Sánchez</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6629/index.html">
                <time dateTime="2006-12-11T17:12:54+00:00">Lunes, diciembre 11, 2006 a las 5:12:PM</time>
            </a>



                <p>Es la primera vez que visito ésta página y me llamó mucho la atención
            los felicito y me gustarí que publicaran o me enviaran un recetario acerca
            de todo loque se puede hacer del frijol de soya ya que mi esposa recibió
            un curso al respecto pero prestó el libro y ya no se lo devolvieron.
            les agradeceré mucho me tomen en cuenta.Dios los bendiga.</p>


            </li>
                <li id="comment-6630" className="comment even thread-even depth-1">


                <b className="fn">Jesús Enríqez</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6630/index.html">
                <time dateTime="2007-02-05T18:14:30+00:00">Lunes, febrero 5, 2007 a las 6:14:PM</time>
            </a>



                <p>Hola Ilse, Me parece su páguina muy interesante. Una duda que tengo acerca
            de el frijol de soya, es cómo y cuál es la manera de fermentarlo ? y qué
            producto se obtiene de la fermentación ? Esperando su respuesta. Le agradezco
            la ayuda que pueda usted proporcionarme..</p>


            </li>
                <li id="comment-6631" className="comment odd alt thread-odd thread-alt depth-1">


                <b className="fn">Angela judith</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6631/index.html">
                <time dateTime="2007-02-17T10:13:29+00:00">Sábado, febrero 17, 2007 a las 10:13:AM</time>
            </a>



                <p>Hola Ilse:tengo un bebe de 8 meses, el toma hasta el momento leche de
            formula, la materna solo hasta los 6 1/2 pq ya no tenia mucha leche.
            Mi esposo me comento sobre la leche de soya, investigando en la web
            encontre tu pg, estamos pensando en cambiarle la leche de formula
            pq al parecer tiene alguna reacción alergica (estamos por consultar a su pediatrar)
            mi pregunta es la siguientes si le pasamos a dar la leche de soya casera
            tendra algun inconveniente en la baja de proteinas ó vitaminas
            (se que la leche de soya es buena) pero en el caso del bb podría hacer
            alguna reacción. Es recomendada para su tiempo?
            Muchas gracias.</p>


            </li>
                <li id="comment-6632" className="comment even thread-even depth-1">


                <b className="fn">Juan Jose</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6632/index.html">
                <time dateTime="2007-02-23T16:36:33+00:00">Viernes, febrero 23, 2007 a las 4:36:PM</time>
            </a>



                <p>Hola es primera vez que visito esta pagina y es muy interesante me sirvio mucho la receta de la leche y del yogurt soy estudiante del octavo semestre de Ing. Agroindustrial y tengo que presentar un proyecto y he escogido el de yogurt de soya me gustaria haber si me pueden ayudar a dar un nombre comercial a este producto cualquier sugerencia es buena gracias estamos en contacto</p>


            </li>
                <li id="comment-6633" className="comment odd alt thread-odd thread-alt depth-1">


                <b className="fn">Olga</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6633/index.html">
                <time dateTime="2007-02-23T16:42:39+00:00">Viernes, febrero 23, 2007 a las 4:42:PM</time>
            </a>



                <p>Hola, soy profesora de primaria, y en ela escuela en donde trabajo tenemos
            un desayunador escolar, necesito urgentemente recetas sencillas, economicas
            y ricas para elaborar con soya para nuestros alumnos.
            Bye.</p>


            </li>
                <li id="comment-6634" className="comment even thread-even depth-1">


                <b className="fn">silvia de Mata</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6634/index.html">
                <time dateTime="2007-03-19T09:23:27+00:00">Lunes, marzo 19, 2007 a las 9:23:AM</time>
            </a>



                <p>Gracias por tan buena información sobre Soya.</p>
                <p>A Juan Jose le sugiero: GURDESOYA para su yogurt y ánimo con su proyecto,</p>
                <p>A Victor Lopez dirigirse a la Corporación Universitaria Adventista en su Ciudad
            ellos deberían tener recetario.</p>


            </li>
                <li id="comment-6635" className="comment odd alt thread-odd thread-alt depth-1">


                <b className="fn">silvia de Mata</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6635/index.html">
                <time dateTime="2007-03-19T09:46:39+00:00">Lunes, marzo 19, 2007 a las 9:46:AM</time>
            </a>



                <p>Con la okara podemos preparar: waffles, panquecas, arepas, revoltillo, croque-
            tas.</p>
                <p>REVOLTILLO:
            3 TALLOS DE CEBOLLIN PICADITOS
            3 TOMATES PEQUEÑOS PICADITOS
            SAL AL GUSTO
            1 DIENTE DE AJO PICADITO
            1 TAZA DE OKARA</p>
                <p>Sofreír los aliños, agregar la okara revolviendo, hasta conseguir apariencia de
            huevos revueltos cuidando de no dejar secar mucho.</p>
                <p> .</p>
                <p>2 CUCHARADAS DE ACEITE
            1 TAZA DE OKARA</p>


            </li>
                <li id="comment-6636" className="comment even thread-even depth-1">


                <b className="fn">silvia de Mata</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6636/index.html">
                <time dateTime="2007-03-19T09:50:07+00:00">Lunes, marzo 19, 2007 a las 9:50:AM</time>
            </a>



                <p>Para el revoltillo es una sola taza de okara, tuve un error al escribir.
            Buen provecho.</p>


            </li>
                <li id="comment-6637" className="comment odd alt thread-odd thread-alt depth-1">


                <b className="fn">ROSA CARMONA</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6637/index.html">
                <time dateTime="2007-03-20T18:39:16+00:00">Martes, marzo 20, 2007 a las 6:39:PM</time>
            </a>



                <p>ESTOY MUY CONTENTA DE ENCONTRAR ESTA PAGINA PUES ME INTERESA SABER SOBRE ESTOS PRODUCTOS. Y APRENDIENDO RECETAS.</p>


            </li>
                <li id="comment-6638" className="comment even thread-even depth-1">


                <b className="fn">GIL</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6638/index.html">
                <time dateTime="2007-03-29T17:51:25+00:00">Jueves, marzo 29, 2007 a las 5:51:PM</time>
            </a>



                <p>me gustaria saber que tanto ha invadido la soya transgenica entre los consumidores de México, yo vivo en tabasco y consumo la soya en grano pero no se si es de origen transgenico.</p>


            </li>
                <li id="comment-6639" className="comment odd alt thread-odd thread-alt depth-1">


                <b className="fn">Obdulio gomez</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6639/index.html">
                <time dateTime="2007-04-04T21:25:16+00:00">Miércoles, abril 4, 2007 a las 9:25:PM</time>
            </a>



                <p>estoy en el interes de elaborar leche de soya en polvo,a partir
            de aislado de proteina, alguien quisiera ayudarme sugiriendome una formula</p>


            </li>
                <li id="comment-6640" className="comment even thread-even depth-1">


                <b className="fn">Ana</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6640/index.html">
                <time dateTime="2007-04-11T08:04:50+00:00">Miércoles, abril 11, 2007 a las 8:04:AM</time>
            </a>



                <p>Hola me interesan mucho recetas acerca de soya deshidratada,tengo acceso aelle y no sécómoi</p>


            </li>
                <li id="comment-6641" className="comment odd alt thread-odd thread-alt depth-1">


                <b className="fn"><a href="http://www.google.com" rel="external nofollow" className="url">lady</a></b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6641/index.html">
                <time dateTime="2007-04-16T05:33:52+00:00">Lunes, abril 16, 2007 a las 5:33:AM</time>
            </a>



                <p>¿SABER DE QUE ESTA ECHO EL YOGURT
            DELAS PLANTAS O DELAS VACAS?</p>


            </li>
                <li id="comment-6642" className="comment even thread-even depth-1">


                <b className="fn">Paulina</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6642/index.html">
                <time dateTime="2007-04-17T14:42:12+00:00">Martes, abril 17, 2007 a las 2:42:PM</time>
            </a>



                <p>Agradeceria me enviara como hacer la leche de soya y que puedo hacer con el residuo y como
            Gracias</p>


            </li>
                <li id="comment-6643" className="comment odd alt thread-odd thread-alt depth-1">


                <b className="fn">carolina</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6643/index.html">
                <time dateTime="2007-04-21T20:30:15+00:00">Sábado, abril 21, 2007 a las 8:30:PM</time>
            </a>



                <p>hola, tu pagina es muy interesante y por eso tengo una pregunta para ti:
            tengo bacilos bulgaros, pero el yogurt que producen es muy acido ¿que
            puedo hacer para que su sabor sea mas suave y menos acido
            de forma natural (sin añadir algun tipo de azucar?
                              De antemano muchas gracias.</p>


                              </li>
                              <li id="comment-6644" className="comment even thread-even depth-1">


                              <b className="fn">silvia de Mata</b>

                              <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6644/index.html">
                              <time dateTime="2007-05-10T15:23:18+00:00">Jueves, mayo 10, 2007 a las 3:23:PM</time>
                              </a>



                              <p>Hola, saludos para los visitantes de la página de Ilse, hay todo un tratado
                              en los correos de esta página, a las amigas Ana y Paulina les recomiendo
                              revisar los mensajes anteriores de Ilse y seguro encontrarán lo solicitado.
                              Quería contarles tambien que hay un fabricante en Ecuador que produce una
                              máquina para elaborar leche de soya, en posterior men saje les cuento.</p>


                              </li>
                              <li id="comment-6645" className="comment odd alt thread-odd thread-alt depth-1">


                              <b className="fn">silvia de Mata</b>

                              <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6645/index.html">
                              <time dateTime="2007-05-10T15:36:34+00:00">Jueves, mayo 10, 2007 a las 3:36:PM</time>
                              </a>



                              <p>El fabricante es Rene Robles y su correo electrónico es:
                              <a href="mailto:magnanimusec@gmail.com">magnanimusec@gmail.com</a>
                              Creo que es importante el contacto para Colombia, Venezuela, Perú y Bolivia.</p>


                              </li>
                              <li id="comment-6646" className="comment even thread-even depth-1">


                              <b className="fn">Berthy Saucedo Natusch</b>

                              <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6646/index.html">
                              <time dateTime="2007-05-24T16:52:36+00:00">Jueves, mayo 24, 2007 a las 4:52:PM</time>
                              </a>



                              <p>Yo tengo una pequeña planta procesadora de alimentos de soya “SOYFRUT”
                              y produsco el Yogurt, tofu, la gelatina,</p>


                              </li>
                              <li id="comment-6647" className="comment odd alt thread-odd thread-alt depth-1">


                              <b className="fn">Regina Rivera</b>

                              <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6647/index.html">
                              <time dateTime="2007-06-12T14:43:07+00:00">Martes, junio 12, 2007 a las 2:43:PM</time>
                              </a>



                              <p>Berthy Saucedo me interesa vender tus productos mandame tu telefono</p>


                              </li>
                              <li id="comment-6648" className="comment even thread-even depth-1">


                              <b className="fn">Esthephany</b>

                              <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6648/index.html">
                              <time dateTime="2007-06-20T19:59:01+00:00">Miércoles, junio 20, 2007 a las 7:59:PM</time>
                              </a>



                              <p>hola felicidades por la pagina, todo esta muy interesante, te quiero consultar, tengo un bebe de 3 meses nacio pesando 4 kg, y mi leche materna no le abastecia lloraba xq tenia mas hambre asi que le complete con formula a base de soya (Isomil )adicionada con hierro,omega 3 y omega 6 siemprev le doy pecho y despues toma de 3 a 4 onzas cada 3 horas exepto de las 10pm hasta las 6 am que es cuando duerme. ¿ estoy actuando correctamente o crees q debo darle una formula diferente? (he intentado solo darle mi leche pero no es suficiente para el) ¿hay algo que me de mas leche? puedo continuar como hasta ahora? Mi bebe pesa ahora 8 kg y mide como 65 cm. La formula a base de soya es buena?</p>


                              </li>
                              <li id="comment-6649" className="comment odd alt thread-odd thread-alt depth-1">


                              <b className="fn">amanda</b>

                              <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6649/index.html">
                              <time dateTime="2007-07-10T13:23:04+00:00">Martes, julio 10, 2007 a las 1:23:PM</time>
                              </a>



                              <p>me gustó mucho tu pagina, sobre todo por que la soya es un produto noble,
                              fue muy interesante enterarse de todas las propiedades que contiene la soya.
                              Dejame felicitarte por que son pocas las personas que que realmente se
                              interesan por la salud y gracias por ayudar a muchas personas a resolver sus
                              dudas.
                              La soya es un producto que le servira a las presentes y futuras generaciones
                              sin afectar su salud.
                              Me interesa saber mas acerca del yogurt casero
                              ¿ podría ponerle algun
                              saborizante artificial sin afectar la salud? o existe un saborizante natural?
                              pero que no sea la vainilla.</p>


                              </li>
                              <li id="comment-6650" className="comment even thread-even depth-1">


                              <b className="fn">VERONICA PEÑA</b>

                              <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6650/index.html">
                              <time dateTime="2007-07-16T08:33:48+00:00">Lunes, julio 16, 2007 a las 8:33:AM</time>
                              </a>



                              <p>Hola Ilse,felicidades por tu pagina es primera vez que la visito porque no había tenido le necesidad de hacerlo,pero actualmente tengo 45 años y estoy entrando en la menopausia y se em recomendo medicamente consumir la leche de soya pero PREPARADA POR MI ,no en polvo y afortunadamenmte tu pñagi página fue la que salio primero y me voy a servir de sus comentarios y recetas para elaborar mñas comidas porque lo unico que actualmente utilizo desde hace 25 años en que me casé es la soya texturizada y tiene muy buena aceptación en mi familia.Me intereza adquirir el recetario de Guillermina quisiera si puede se comunicara conmigo por este medio si le es posible porque soy novata en esto de la computadora y no se como hacer para comunicarme con ella.Vivo en Tuxpan ,Jalisco.hasta pronto.saludos.</p>


                              </li>
                              <li id="comment-6651" className="comment odd alt thread-odd thread-alt depth-1">


                              <b className="fn">JESSICA</b>

                              <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6651/index.html">
                              <time dateTime="2007-07-16T17:13:26+00:00">Lunes, julio 16, 2007 a las 5:13:PM</time>
                              </a>



                              <p>HOLA MUY INTERESANTE ME GUSTARIA QUE ME ENSE4ÑARAS A PREPARAR LA SOYA CASERA EN CANTIDADES EXACTAS NI MUCHA AGUA O MUCHA SOYA PORFIS ES PARA MI NIÑO DE 2 AÑOS</p>


                              </li>
                              <li id="comment-6652" className="comment even thread-even depth-1">


                              <b className="fn">Esmeralda</b>

                              <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6652/index.html">
                              <time dateTime="2007-07-30T21:36:35+00:00">Lunes, julio 30, 2007 a las 9:36:PM</time>
                              </a>



                              <p>hola! gracias por todos sus comentarios, muy valiosos. los lei todos,</p>
                              <p>ya que tengo pocos meses comiendo la soya, y quiero aprender mas,
                              no se si Ilse va a seguir contestando las ultimas preguntas que
                              le han hecho, ya no veo que conteste, bueno Ilse se que tienes
                              muchas cosas por hacer, y tu espacio es bastante intereresante
                              felicitaciones, cuidate mucho y saludos</p>


                              </li>
                              <li id="comment-6653" className="comment odd alt thread-odd thread-alt depth-1">


                              <b className="fn">GRACIELA HERRERA MONROY</b>

                              <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6653/index.html">
                              <time dateTime="2007-08-23T07:04:02+00:00">Jueves, agosto 23, 2007 a las 7:04:AM</time>
                              </a>



                              <p>Estoy promoviendo un concurso de alimentos con soya con las trabajadoras de una empresa, quisiera enseñarles a preparar sus platillos, donde puedo conseguir las recetas me pueden ayudar.
                              Felicitaciones por su pagina, saludos</p>


                              </li>
                              <li id="comment-6654" className="comment even thread-even depth-1">


                              <b className="fn">nancy vgs</b>

                              <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6654/index.html">
                              <time dateTime="2007-08-27T10:37:39+00:00">Lunes, agosto 27, 2007 a las 10:37:AM</time>
                              </a>



                              <p>me gustaria que contestaras la pregunta de ESTHEPHANY ya que mi bebe tiene la misma eda</p>


                              </li>
                              <li id="comment-6655" className="comment odd alt thread-odd thread-alt depth-1">


                              <b className="fn">nancy vgs</b>

                              <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6655/index.html">
                              <time dateTime="2007-08-27T10:38:32+00:00">Lunes, agosto 27, 2007 a las 10:38:AM</time>
                              </a>



                              <p>por favor contesta la pregunta de Esthephany.</p>


                              </li>
                              <li id="comment-6656" className="comment even thread-even depth-1">


                              <b className="fn"><a href="http://example.com" rel="external nofollow" className="url">partrick</a></b>

                              <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6656/index.html">
                              <time dateTime="2007-09-09T08:12:31+00:00">Domingo, septiembre 9, 2007 a las 8:12:AM</time>
                              </a>



                              <p>bfdbfbbfbfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff</p>


                              </li>
                              <li id="comment-6657" className="comment odd alt thread-odd thread-alt depth-1">


                              <b className="fn">Denisse</b>

                              <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6657/index.html">
                              <time dateTime="2007-11-02T18:30:42+00:00">Viernes, noviembre 2, 2007 a las 6:30:PM</time>
                              </a>



                              <p>porfa alguien me puede ayudar dieciendome como se hacen los chicharrones
                              de soya por q eh intentado con carne de soya pero parece carne frita porfa
                              ayuda!! <img draggable="false" className="emoji" alt="🙂" src={happySmiley} /> saludos desde ecuador</p>


                              </li>
                              <li id="comment-6658" className="comment even thread-even depth-1">


                              <b className="fn">yadisofifa</b>

                              <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6658/index.html">
                              <time dateTime="2007-11-16T16:02:23+00:00">Viernes, noviembre 16, 2007 a las 4:02:PM</time>
                              </a>



                              <p>hola los felicitos por todo los conocimientos que tienen de los beneficios que tiene la soya y les solicito el favor de ayudarmen con la receta del queso de la soya por que no logro que adquiera la contextura adecuada</p>


                              </li>
                              <li id="comment-6659" className="comment odd alt thread-odd thread-alt depth-1">


                              <b className="fn">eduardo Ortega</b>

                              <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6659/index.html">
                              <time dateTime="2007-11-22T12:33:59+00:00">Jueves, noviembre 22, 2007 a las 12:33:PM</time>
                              </a>



                              <p>Antes que nada felicitarlos por su excelente página llena con mucha información necesaria, mi conculta es la siguiente, hace unos días tuve la oportunidad de probar unos “chicharrones de soya” que en realidad eran unos chips u hojuelas de soya fritas como cualquir chip de papa que hay en le mercado, yo queria saber si es que tienen alguna receta disponible, ya que son mas sanas que cualquier papa frita y por suerte ami hijo le encantaron, ls menciono que las probe de mera casualidad y no tengo como obtener el producto o la receta para prepararlasyo mismo, agradezco de antemano su gentil respuesta , ojalá puedan ayudarme. gracias</p>


                              </li>
                              <li id="comment-6660" className="comment even thread-even depth-1">


                              <b className="fn">edith</b>

                              <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6660/index.html">
                              <time dateTime="2007-11-29T11:45:32+00:00">Jueves, noviembre 29, 2007 a las 11:45:AM</time>
                              </a>



                              <p>hola!
                              antes que nada quiero felicitarlos por esta pagina pues es muy interesante mi esposo y yo tenemos poco de ser vegetarianos y les confienso que haveces ya no sabia que hacer de comer pero gracias a estos sitios he aprendido varias recetas</p>


                              </li>
                              <li id="comment-6661" className="comment odd alt thread-odd thread-alt depth-1">


                              <b className="fn">alejandra</b>

                              <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6661/index.html">
                              <time dateTime="2008-03-13T08:42:53+00:00">Jueves, marzo 13, 2008 a las 8:42:AM</time>
                              </a>



                              <p>que interesante es toda esta informacion que se encuentra aqui.
                              estaba buscando una receta para hacer yogurt de leche de soya,
                              voy a probar con la que encontre aqui y despues les cuento que tal me fue.
                              mucha gracias y saludos…</p>


                              </li>
                              <li id="comment-6662" className="comment even thread-even depth-1">


                              <b className="fn">jairo</b>

                              <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6662/index.html">
                              <time dateTime="2008-03-28T20:41:36+00:00">Viernes, marzo 28, 2008 a las 8:41:PM</time>
                              </a>



                              <p>hola a todos es interesante todo lo ke se ha hablado en esta web la verdad soy vegetariano desde hace 15 años y mi estado de salud es de lo mejor , kisiera saber muchas mas recetas a
                              base de soya , en especial salchichas vegetarianas a base de soya</p>


                              </li>
                              <li id="comment-6663" className="comment odd alt thread-odd thread-alt depth-1">


                              <b className="fn"><a href="http://example.com" rel="external nofollow" className="url">zuly</a></b>

                              <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6663/index.html">
                              <time dateTime="2008-05-16T15:22:33+00:00">Viernes, mayo 16, 2008 a las 3:22:PM</time>
                              </a>



                              <p>Hola Ilce</p>
                              <p> tengo una gran inquietud, quiero elaborar un yogurt a base de leche de soya para comercializarlo pero por lo que se del yogurt es que es el resultado de la fermentacion lactica de la lactosa de la leche pecomo hago para poder lograr una cierta consistencia y que a la vez cumpla con los requerimientos bromatologiccos que debe contener el yogurt si como es sabido que la leche de soya no contiene lactosa.</p>


                              </li>
                              <li id="comment-6664" className="comment even thread-even depth-1">


                              <b className="fn"><a href="http://bolivia.com" rel="external nofollow" className="url">Marcelo Gallo</a></b>

                              <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6664/index.html">
                              <time dateTime="2008-05-17T08:49:18+00:00">Sábado, mayo 17, 2008 a las 8:49:AM</time>
                              </a>



                              <p>POR FA SI ME PRODRIA AYUDAR CON UNA RECETA DE HAMBURGUESA DE SOYA LES AGRDECERIA MUCHO</p>


                              </li>
                              <li id="comment-6665" className="comment odd alt thread-odd thread-alt depth-1">


                              <b className="fn"><a href="http://www.geogle.com" rel="external nofollow" className="url">jose</a></b>

                              <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6665/index.html">
                              <time dateTime="2008-06-02T08:27:51+00:00">Lunes, junio 2, 2008 a las 8:27:AM</time>
                              </a>



                              <p>su informaciones son muy buenas</p>


                              </li>
                              <li id="comment-6666" className="comment even thread-even depth-1">


                              <b className="fn"><a href="http://www.geogle.com" rel="external nofollow" className="url">jose</a></b>

                              <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6666/index.html">
                              <time dateTime="2008-06-02T08:31:31+00:00">Lunes, junio 2, 2008 a las 8:31:AM</time>
                              </a>



                              <p>por favor me pueden facilitar el proceso de elaboracion de la carne de soya, con sus porcentajes de cada uno de los aditivos,una pregunta el consumo de la cascara ya hidratada afecta en algo en la elaboracion de embutidos….</p>


                              </li>
                              <li id="comment-6667" className="comment odd alt thread-odd thread-alt depth-1">


                              <b className="fn">Maru</b>

                              <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6667/index.html">
                              <time dateTime="2008-07-19T23:20:41+00:00">Sábado, julio 19, 2008 a las 11:20:PM</time>
                              </a>



                              <p>saben donde puedo comprar en Monterrey Bacilos Bulgaros para elaborar yoghurt</p>


                              </li>
                              <li id="comment-6668" className="comment even thread-even depth-1">


                              <b className="fn"><a href="http://example.com" rel="external nofollow" className="url">Neftali</a></b>

                              <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6668/index.html">
                              <time dateTime="2008-09-02T10:17:30+00:00">Martes, septiembre 2, 2008 a las 10:17:AM</time>
                              </a>



                              <p>Vivo en CHILE y desgraciadamente los productos elaborados con soya no
                              son muy conocidos aquí . Me enteré, en otros lugares, que se puede fabricar
                              carne de soya a partir de su harina mezclándola con el gluten de harina de
                              trigo.
                              ¿ me pueden confirmar esto y en qué proporción se mezcla?
                              Alguna vez compré una especie de masa,al parecer hecha así, que me resultó
                              muy dúctil para preparar muchos guisos y ensaladas.
                              Gracias por su atención</p>


                              </li>
                              <li id="comment-6669" className="comment odd alt thread-odd thread-alt depth-1">


                              <b className="fn">isela</b>

                              <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6669/index.html">
                              <time dateTime="2008-10-03T10:08:07+00:00">Viernes, octubre 3, 2008 a las 10:08:AM</time>
                              </a>



                              <p>hola ilse:
                              me gustaria saber si la soya es libre de gluten ya que mi hijo es celiaco y tiene intolerancia a la lactosa y me gustaria darle leche de soya y ademas productos de soya gracias</p>


                              </li>
                              <li id="comment-6670" className="comment even thread-even depth-1">


                              <b className="fn">Conejo Blash</b>

                              <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6670/index.html">
                              <time dateTime="2008-10-23T23:11:51+00:00">Jueves, octubre 23, 2008 a las 11:11:PM</time>
                              </a>



                              <p>hola muchas gracias por informarnos tanto de la soya quisiera saber donde puedo comprar el nigari que segun yo e leido en varias recetas sirve para preparar tofu igual aqui lei que tambien puedo usar agua de mar pero donde la venden y con limon no creo que sepa tan bien
                              muchas gracias por su cordial atencion</p>


                              </li>
                              <li id="comment-6671" className="comment odd alt thread-odd thread-alt depth-1">


                              <b className="fn">ivan ramos</b>

                              <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6671/index.html">
                              <time dateTime="2009-03-07T10:10:35+00:00">Sábado, marzo 7, 2009 a las 10:10:AM</time>
                              </a>



                              <p>espectacular esta pagina y felicitaciones a todos los q diariamente la estan mejorando.,.. mi pregunta es la siguiente, resulta q ya se como hacer yogurt de soya gracias a la receta enviada por ustedes), pero kisiera preguntar porq este producto tiene sabor como a ron…</p>
                <p>q posibilidades hay tambien de hacer yogurt mezclando la leche de soya con leche deslactosada o descremada???</p>
                <p>gracias !!</p>


            </li>
                <li id="comment-6672" className="comment even thread-even depth-1">


                <b className="fn">fabiola</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6672/index.html">
                <time dateTime="2009-03-13T17:56:13+00:00">Viernes, marzo 13, 2009 a las 5:56:PM</time>
            </a>



                <p>a cuanto equivalen 30 gramos de soya texturizada en isoflavonas</p>


            </li>
                <li id="comment-6673" className="comment odd alt thread-odd thread-alt depth-1">


                <b className="fn">ivan ramos</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6673/index.html">
                <time dateTime="2009-03-30T13:39:37+00:00">Lunes, marzo 30, 2009 a las 1:39:PM</time>
            </a>



                <p>Pero contesten porq uno aca pierde el tiempo esperando q alguno le responda !!
            Lo unico malo de esta pagina lastima</p>


            </li>
                <li id="comment-6674" className="comment even thread-even depth-1">


                <b className="fn">ivan ramos</b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6674/index.html">
                <time dateTime="2009-03-30T13:40:52+00:00">Lunes, marzo 30, 2009 a las 1:40:PM</time>
            </a>



                <p>Desde el 7 de marzo esperando respuesta NO NO NO lastima !!!</p>


            </li>
                <li id="comment-6675" className="comment odd alt thread-odd thread-alt depth-1">


                <b className="fn"><a href="http://example.com" rel="external nofollow" className="url">rotten</a></b>

                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6675/index.html">
                <time dateTime="2009-05-10T15:00:20+00:00">Domingo, mayo 10, 2009 a las 3:00:PM</time>
            </a>



                <p>diskulpa
            pero kisiera saber donde ñpudiera konseguir gelatina vegana
            o chorrizo 100% de soya o kualkier karne artificial(no animal y sin kontenidos de webo,leche o residus de grasa animal,
                                                                saludos y
                                                                no tirania animal</p>


                                                                </li>
                                                                <li id="comment-6676" className="comment even thread-even depth-1">


                                                                <b className="fn"><a href="http://example.com" rel="external nofollow" className="url">rotten</a></b>

                                                                <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6676/index.html">
                                                                <time dateTime="2009-05-10T15:02:16+00:00">Domingo, mayo 10, 2009 a las 3:02:PM</time>
                                                                </a>



                                                                <p>soy de monterrey
                                                                y kisiera konseguir o saber donde pudiera konseguir gelatina vegana
                                                                o chorrizo 100% de soya o kualkier karne artificial(no animal y sin kontenidos de webo,leche o residus de grasa animal,
                                                                                                                    saludos y
                                                                                                                    no tirania animal
                                                                                                                    saludos a todos</p>


                                                                                                                    </li>
                                                                                                                    <li id="comment-6677" className="comment odd alt thread-odd thread-alt depth-1">


                                                                                                                    <b className="fn">viviana vergara</b>

                                                                                                                    <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6677/index.html">
                                                                                                                    <time dateTime="2009-05-20T10:51:38+00:00">Miércoles, mayo 20, 2009 a las 10:51:AM</time>
                                                                                                                    </a>



                                                                                                                    <p>hola ilse </p>
                                                                                                                    <p>tengo un bebe de 9 meses con alergia a la proteina de la leche de vaca y
                                                                                                                    por mi trabajo hace 2 meses la alimento con pecho y leche de soya en polvo
                                                                                                                    para bebes, la verdad es que es muy costosa, quisiera saber si puedo darle
                                                                                                                    leche de soya casera eso espero tu respuesta</p>


                                                                                                                    </li>
                                                                                                                    <li id="comment-6678" className="comment even thread-even depth-1">


                                                                                                                    <b className="fn">beatriz</b>

                                                                                                                    <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6678/index.html">
                                                                                                                    <time dateTime="2009-06-04T12:30:26+00:00">Jueves, junio 4, 2009 a las 12:30:PM</time>
                                                                                                                    </a>



                                                                                                                    <p>hola espero me puedan contestar mi pregunta donde puedo comprar el frijol de soya en puebla pue. México soy deabetica y quisiera empezar a consumir el tofu gracias´por tomarse el tiempo e cotestarme. bay</p>


                                                                                                                    </li>
                                                                                                                    <li id="comment-6679" className="comment odd alt thread-odd thread-alt depth-1">


                                                                                                                    <b className="fn">klaudia</b>

                                                                                                                    <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6679/index.html">
                                                                                                                    <time dateTime="2009-06-06T18:02:20+00:00">Sábado, junio 6, 2009 a las 6:02:PM</time>
                                                                                                                    </a>



                                                                                                                    <p>Tengo un peke que es alergico a los lacteos y sus derivados, y toma leche de soya, y por lo mismo su dieta es muy pobre, me han comentado que existen quesos, yogurt, crema, entre otros de soya y me interesa saber si es que esto existe y donde los puedo adquirir, para asi poder alimentar mejor a mi bebé, ya que le encanta todo esto además de poder darle pastel el día de su cumple, por fa, ayudenme a saber más sobre este tema porque para mi es nuevo… muchas gracias.</p>


                                                                                                                    </li>
                                                                                                                    <li id="comment-6680" className="comment even thread-even depth-1">


                                                                                                                    <b className="fn">aida</b>

                                                                                                                    <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6680/index.html">
                                                                                                                    <time dateTime="2009-08-18T12:49:53+00:00">Martes, agosto 18, 2009 a las 12:49:PM</time>
                                                                                                                    </a>



                                                                                                                    <p>me encanto esta pagina y solo quiero contribuir a la preparacion de la soya textirizada.
                                                                                                                    Esto ayuda a que desaparesca el “sabor a soya” y adquiera el sabor del guiso que desees
                                                                                                                    1- Hervir agua ( la suficiente para cubrir la cantidad de soya q vas a preparar)
                                                                                                                    2-incorporar la soya y poner unas gotas de limon, hiervas de olor (si gustas)
                                                                                                                    polvo de pollo.
                                                                                                                    3-Quitar la espuma que se va formando en la olla.
                                                                                                                    4- Hervir aprox 20- 30min.
                                                                                                                    5- y la clave…. enjuagarla perfectamente !! bien despues de hervirla, a tal grado q en el recipiente que la
                                                                                                                    estes enjuagando el agua salga clara.
                                                                                                                    6- por ultimo exprimirla con un paño… y lisata para prearar con mole verde, carnitas, esnsalada, etc
                                                                                                                    Se que este procedimiento es un poco laborioso pero de verdad vale la pena y nadie detectara el sabor de la soya.</p>


                                                                                                                    </li>
                                                                                                                    <li id="comment-6681" className="comment odd alt thread-odd thread-alt depth-1">


                                                                                                                    <b className="fn">angie</b>

                                                                                                                    <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6681/index.html">
                                                                                                                    <time dateTime="2009-08-27T15:20:28+00:00">Jueves, agosto 27, 2009 a las 3:20:PM</time>
                                                                                                                    </a>



                                                                                                                    <p>hola, tengo bulgaros de leche y los preparo con leche de vaca, puedo suplir la leche de vaca con leche de soya .</p>
                                                                                                                    <p>gracias</p>


                                                                                                                    </li>
                                                                                                                    <li id="comment-6682" className="comment even thread-even depth-1">


                                                                                                                    <b className="fn">VERONICA</b>

                                                                                                                    <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6682/index.html">
                                                                                                                    <time dateTime="2009-09-01T12:50:52+00:00">Martes, septiembre 1, 2009 a las 12:50:PM</time>
                                                                                                                    </a>



                                                                                                                    <p>hola!!!
                                                                                                                    Soy de Chihuahua y anteriormente escribió Guillermina Sales de Queretaro que da talleres de nutricion y cocina vegetariana.
                                                                                                                    yo soy vegetariana y me gustaria saber donde puedo conseguir el manual que elaboró. Así mismo si me pudieras proporcionar su correo para contactarme con ella.</p>
                                                                                                                    <p>gracias</p>


                                                                                                                    </li>
                                                                                                                    <li id="comment-6683" className="comment odd alt thread-odd thread-alt depth-1">


                                                                                                                    <b className="fn">Jacky</b>

                                                                                                                    <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6683/index.html">
                                                                                                                    <time dateTime="2009-09-02T15:24:17+00:00">Miércoles, septiembre 2, 2009 a las 3:24:PM</time>
                                                                                                                    </a>



                                                                                                                    <p>hola ilse, tengo una bebe de 11 meses y toma aun la leche materna pero por motivos de estudio tengo q dejarla y en esos momentos tengo q darle leche de formula y no quiere tomarla, hasta q un dia mi mama le dio la leche de soya que prepara en casa, es de los frejoles de soya q los hace hervir, y le dio el liquido y lo tomo normal mi hija, no se si sera muy aguada para dale o si esta bien darle eso mas un suplemento o si no darle la leche de soya mas la leche de formula para que asi no sienta el sabor de la leche de formula ojala me puedas ayudar .. gracias</p>


                                                                                                                    </li>
                                                                                                                    <li id="comment-6684" className="comment even thread-even depth-1">


                                                                                                                    <b className="fn">araceli</b>

                                                                                                                    <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6684/index.html">
                                                                                                                    <time dateTime="2009-09-27T16:03:48+00:00">Domingo, septiembre 27, 2009 a las 4:03:PM</time>
                                                                                                                    </a>



                                                                                                                    <p>HOLA ILSE
                                                                                                                    HERMOSISIMA TU PAGINA, LLENA DE UTILISIMOS CONSEJOS
                                                                                                                    YO TAMBIEN SOY VEGETARIANA (ESTOY EMPEZANDO ) .
                                                                                                                    VIVO EN CLIMA CALIDO Y ME GUSTARIA HACER EL YOGURT DE SOYA.
                                                                                                                    TAMBIEN ME GUSTARIA SABER SI IMPARTES ALGUN TALLER O SI HAS ESCRITO ALGUN LIBRO CON RECETAS
                                                                                                                    GRACIAS</p>


                                                                                                                    </li>
                                                                                                                    <li id="comment-6685" className="comment odd alt thread-odd thread-alt depth-1">


                                                                                                                    <b className="fn">ana maria</b>

                                                                                                                    <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6685/index.html">
                                                                                                                    <time dateTime="2009-10-24T08:36:06+00:00">Sábado, octubre 24, 2009 a las 8:36:AM</time>
                                                                                                                    </a>



                                                                                                                    <p>me encuentro ubicada en venezuela alguien podria por favor decirme donde puedo comprar bacilos bulgaros en caracas gracias</p>


                                                                                                                    </li>
                                                                                                                    <li id="comment-6686" className="comment even thread-even depth-1">


                                                                                                                    <b className="fn"><a href="http://example.com" rel="external nofollow" className="url">anonimo</a></b>

                                                                                                                    <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6686/index.html">
                                                                                                                    <time dateTime="2010-05-13T11:44:16+00:00">Jueves, mayo 13, 2010 a las 11:44:AM</time>
                                                                                                                    </a>



                                                                                                                    <p>hola ilse gracias por decirme com preparar yogurt co leche de soya</p>


                                                                                                                    </li>
                                                                                                                    <li id="comment-6687" className="comment odd alt thread-odd thread-alt depth-1">


                                                                                                                    <b className="fn"><a href="http://www.skesit.com" rel="external nofollow" className="url">Agustin Diaz</a></b>

                                                                                                                    <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6687/index.html">
                                                                                                                    <time dateTime="2010-05-21T20:21:27+00:00">Viernes, mayo 21, 2010 a las 8:21:PM</time>
                                                                                                                    </a>



                                                                                                                    <p>Hola! una felicitacion por tu sitio web y por la cantidad de personas a las que estas brindado orientación, en mi caso estamos fabricando Tofu en la ciudad de Toluca, Mexico y sitios como el tuyo nos ayudan a difundir los beneficios de la soya y el Tofu, les dejo un enlace que seguramente sera de su interes. Saludos</p>
                                                                                                                    <p><a href="http://example.com" rel="nofollow">http://example.com</a></p>


                                                                                                                    </li>
                                                                                                                    <li id="comment-6688" className="comment even thread-even depth-1">


                                                                                                                    <b className="fn">Luz</b>

                                                                                                                    <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6688/index.html">
                                                                                                                    <time dateTime="2010-10-05T11:07:29+00:00">Martes, octubre 5, 2010 a las 11:07:AM</time>
                                                                                                                    </a>



                                                                                                                    <p>Gracias a Dios por su página, además de felicitarlos.</p>
                                                                                                                    <p>Quiero saber, si es posible, ¿ustedes tienen la receta de pan de soya?</p>
                                                                                                                    <p>Agradecería enormemente si alguien me la regala.</p>


                                                                                                                    </li>
                                                                                                                    <li id="comment-6689" className="comment odd alt thread-odd thread-alt depth-1">


                                                                                                                    <b className="fn">Alicia</b>

                                                                                                                    <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6689/index.html">
                                                                                                                    <time dateTime="2011-10-03T06:37:34+00:00">Lunes, octubre 3, 2011 a las 6:37:AM</time>
                                                                                                                    </a>



                                                                                                                    <p>Primeramente quiero agradecer por la respuesta que me puedan brindar.</p>
                                                                                                                    <p>Les comento que yo preparo la soya en la mañanas, remojo los frijoles de un día para otro y al día siguiente lo licuo, lo hago hervir y luego al final lo cuelo y el sobrando de lo que colé, lo boto!!!! mi pregunta es, que se puede preparar con el restante de la soya ya utilizada.</p>
                                                                                                                    <p>si me dieran una receta, sería bueno de mis hijos y sho se los agradeceriamos!!!!</p>


                                                                                                                    </li>
                                                                                                                    <li id="comment-6690" className="comment even thread-even depth-1">


                                                                                                                    <b className="fn">CHICHARRON DE SOYA</b>

                                                                                                                    <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-6690/index.html">
                                                                                                                    <time dateTime="2011-12-31T09:37:10+00:00">Sábado, diciembre 31, 2011 a las 9:37:AM</time>
                                                                                                                    </a>



                                                                                                                    <p>SALUDOS:
                                                                                                                    ESPERO ME PUEDAN AYUDAR CON LA RECETA PARA PREPARAR CHICHARRON DE SOYA
                                                                                                                    GRACIAS</p>


                                                                                                                    </li>
                                                                                                                    <li id="comment-7075" className="comment odd alt thread-odd thread-alt depth-1">


                                                                                                                    <b className="fn">paola</b>

                                                                                                                    <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-7075/index.html">
                                                                                                                    <time dateTime="2015-01-19T22:26:12+00:00">Lunes, enero 19, 2015 a las 10:26:PM</time>
                                                                                                                    </a>



                                                                                                                    <p>hola ilse, me gustaria saber si me podrias ayudar, lo que pasa es que tengo una hija de 13 años y queremos empezar una vida vegetariana, pero no se si esto podria afectarle a mi hija en su crecimiento, me gustaria conocer tu opinion al respecto
                                                                                                                    , gracias.</p>


                                                                                                                    </li>
                                                                                                                    <li id="comment-7082" className="comment even thread-even depth-1">


                                                                                                                    <b className="fn">Gerardo Ramos</b>

                                                                                                                    <a href="https://www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/#comment-7082/index.html">
                                                                                                                    <time dateTime="2015-06-04T13:37:04+00:00">Jueves, junio 4, 2015 a las 1:37:PM</time>
                                                                                                                    </a>



                                                                                                                    <p>Hola tengo unas preguntas ¿porque causa se debe de colar la espuma del licuado de los frijoles de soya?, ¿la nata de la leche de soya también la puedo utilizar para comer como galletas de nata etc.? espero me apoyen gracias; y si es muy interesante esta pagina.</p>


                                                                                                                    </li>
                                                                                                                    </ol>
                                                                                                                    <p className="no-comments">Los comentarios están cerrados.</p>
                                                                                                                    </div>
                                                                                                                    </div>
                                                                                                                    </div>
</LazyLoad>
                                                                                                                   );
                                                               }
        }

        export default CommentsSoya;
